export default class Pagination {
  constructor() {
    const paginationNumbers = document.getElementById('pagination-numbers');

    if (paginationNumbers) {
      this.paginationNumbers = paginationNumbers;
      this.initPagination();
    }
  }

  initPagination() {
    const paginatedList = document.getElementById('paginated-table');
    const listItems = paginatedList.querySelectorAll('tr');
    const nextButton = document.getElementById('next-button');
    const prevButton = document.getElementById('prev-button');

    const itemsPerPage = 10; // Show 4 items per page
    const paginationLimit = 5; // Limit the number of visible page numbers
    const totalPages = listItems.length;
    let currentPage = 1;

    const disableButton = (button) => {
      if (button) {
        button.classList.add('disabled');
        button.setAttribute('disabled', true);
      }
    };

    const enableButton = (button) => {
      if (button) {
        button.classList.remove('disabled');
        button.removeAttribute('disabled');
      }
    };

    const handlePageButtonsStatus = () => {
      if (currentPage === 1) {
        disableButton(prevButton);
      } else {
        enableButton(prevButton);
      }

      if (currentPage === pageCount) {
        disableButton(nextButton);
      } else {
        enableButton(nextButton);
      }
    };

    const handleActivePageNumber = () => {
      document.querySelectorAll('.pagination-number').forEach((button) => {
        const pageIndex = Number(button.getAttribute('page-index'));
        if (pageIndex === currentPage) {
          button.classList.add('active');
        } else {
          button.classList.remove('active');
        }
      });
    };

    const appendPageNumber = (index) => {
      const pageNumber = document.createElement('button');
      pageNumber.className = 'pagination-number';
      pageNumber.innerHTML = index;
      pageNumber.setAttribute('page-index', index);
      pageNumber.setAttribute('aria-label', 'Page ' + index);

      pageNumber.addEventListener('click', () => {
        setCurrentPage(index);
      });

      this.paginationNumbers.appendChild(pageNumber);
    };

    const getPaginationNumbers = () => {
      this.paginationNumbers.innerHTML = '';
      let startPage = Math.max(currentPage - 1, 1);

      for (let i = startPage; i <= Math.min(startPage + paginationLimit - 1, pageCount); i++) {
        appendPageNumber(i);
      }
    };

    const setCurrentPage = (pageNum) => {
      currentPage = pageNum;

      handlePageButtonsStatus();

      const prevRange = (pageNum - 1) * itemsPerPage;
      const currRange = pageNum * itemsPerPage;

      listItems.forEach((item, index) => {
        item.classList.add('d-none');
        if (index >= prevRange && index < currRange) {
          item.classList.remove('d-none');
        }
      });

      handleActivePageNumber();
    };

    const pageCount = Math.ceil(totalPages / itemsPerPage);

    window.addEventListener('load', () => {
      if (totalPages < itemsPerPage) {
        prevButton.classList.add('d-none');
        nextButton.classList.add('d-none');
      } else {
        getPaginationNumbers();
        setCurrentPage(1);

        prevButton.addEventListener('click', () => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            getPaginationNumbers(); // Update the displayed page numbers
            handleActivePageNumber();
          }
        });

        nextButton.addEventListener('click', () => {
          if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
            getPaginationNumbers(); // Update the displayed page numbers
            handleActivePageNumber();
          }
        });
      }
    });
  }
}