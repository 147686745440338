function getSelected(caller, URL, GRID) {
  if(GRID && caller.$('#' + GRID ).length){
    if (caller.$.fn.DataTable.isDataTable('#' + GRID )) {
      var rows = caller.$('#' + GRID ).DataTable().rows('.selected').data();
    } else {
      var rows = caller.$('#' + GRID ).datagrid('getSelections');
    }
    var selected = '';
    var selectedRows = [];
    for (var i = 0; i < rows.length; i++) {
      for (var object_id in rows[i]) {
        if (object_id.indexOf(".") != -1) {
          var object_key = object_id.split(".");
          object_id = object_key[1];
        }
        break;
      }
      var id = rows[i][object_id];
      if (!isNaN(id))
        selectedRows.push(rows[i]['module_object_id'] + '_' + id);
      else
        selectedRows.push(id);
    }
    selected = selectedRows.join(',');
    URL = utils.addParam(URL, "selected", selected);
  }
  return URL;
}
  
function closeAction() {
  $("#actionModalForm").attr("src", "about:blank");
  $('#' + $('.modal.show').attr('id')).modal('hide');
  $(".modal-dismiss").hide();
}

function loadAction(URL, GRID, NAME, ICON, DISPLAY) {
  //Check for size
  if(DISPLAY && DISPLAY.indexOf('large') >= 0 ) {
    $('#actionModalForm .modal-dialog, #objChooserModal .modal-dialog').addClass('modal-lg'); 
  } else {
    $('#actionModalForm .modal-dialog').removeClass('modal-lg'); 
    $('#objChooserModal .modal-dialog').removeClass('modal-lg'); 
  }
  $(".modal-footer.action").show();
  $(".modal-header").show();
  //Check for info - buttons for action modal
  if(DISPLAY && DISPLAY.indexOf('info') >= 0 ) {
    $(".action .modal-ok").show();
    $(".action .modal-dismiss").hide();
    $(".action .modal-confirm").hide();
    $(".action .collab-btns").hide();
  }else if(DISPLAY && DISPLAY.indexOf('delete') >= 0 ) {
    $(".action .modal-ok").hide();
    $(".action .modal-dismiss").show();
    $(".action .modal-confirm").hide();
    $(".action .collab-btns").hide();
  }else if(DISPLAY && DISPLAY.indexOf('collab') >= 0 ) {
    $(".modal-header").hide();
    $(".modal-footer.action").hide();
    $("#actionFrame").css('height', '100vh');
  } else {
    $(".modal-ok").hide();
    $(".action .modal-dismiss").show();
    $(".action .modal-confirm").show();
    $('.action .modal-confirm').removeAttr('disabled');
    $(".action .collab-btns").hide();
  }

  //Show Spinner
  var spinnerMarkup = '<div class="modal-spinner position-absolute" role="status"><i aria-hidden="true" class="fas fa-spinner fa-pulse"></i><span class="sr-only">Loading...</span></div>';
  $("#actionModalForm").find('.modal-body').prepend(spinnerMarkup);

  //Set Title
  var modalTitle = '<i aria-hidden="true" class="'+ICON+' float-left fa-lg mr-3"></i>'+NAME;
  $("#actionModalForm").find('h2.card-title').html(modalTitle);

  if(top.$('#solodev-tabs').length){
    //Get selected tab
    callerView = top.$('#solodev-tabs').find('iframe.active')[0].contentWindow;
    if (callerView.$('#solodev-frame').length ) {
      callerView = callerView.$('#solodev-frame')[0].contentWindow;
    }
  }else if (top.$('#solodev-frame').length ) {
    //Get current iframe
    callerView = top.$('#solodev-frame')[0].contentWindow;
  } else {
    callerView = top;
  }
  
  //If mass action, get selected items
  URL = getSelected(callerView, URL, GRID);

  //Define Submit Button - Executed on submit, not on load
  var formExists;
  var handleSubmit = function() {
    var actionFrame;
    var cloudFrame;

    //check frame - if is a different domain, handle via cloud
    try { 
      actionFrame = window.document.getElementById('actionFrame').contentWindow;
      if(actionFrame.document.body.children.length === 0)
        actionFrame = top; //modal buttons can be on same page now
    } catch(err){
      //error happens when domains dont match - indicating cloud is handling the form
      console.log(err);
      actionFrame = false;
      cloudFrame = window.document.getElementById('actionFrame').contentWindow;
    }
    
    var actionField = '<input type="hidden" value="1" name="action">';
    
    //Check for app forms
    if (actionFrame) {
      var allowedForms = ["contentForm", "appForm"];
      for (var ii = 0; ii < actionFrame.document.forms.length; ii++) {
        if (allowedForms.indexOf($(actionFrame.document.forms[ii]).attr("id")) != -1) {
          $(actionFrame.document.forms[ii]).append( $(actionField) );
          formExists = 1;
          break;
        }
      }

      if (formExists) {
        if ($(actionFrame.document.forms[ii]).length && $(actionFrame.document.forms[ii]).valid()) {
          //Test if onSubmit Function exists
          if (typeof actionFrame.onsubmit == "function") {
            var callbackResult = actionFrame.onsubmit();
            if (callbackResult !== false) {
              actionFrame.document.forms[ii].submit();
            } else{
              $('.modal-confirm').removeAttr('disabled');
            }
          } else if(typeof actionFrame.document.forms[ii].onsubmit == "function"){
            actionFrame.document.forms[ii].onsubmit();
          } else {
            actionFrame.document.forms[ii].submit();
          }
          
        } else {
          $('.modal-confirm').removeAttr('disabled');
        }
      } 
    } else if (cloudFrame) {
      //postMessage to submit form via the cloud
      var frameSource = 'https://www.solodev.com';
      cloudFrame.postMessage("handleSubmit",frameSource);
      //refresh page after it loads
      $('#actionFrame').on("load", function () {
        //refresh page
        window.location = window.location;
      });
    }

    if(GRID) {
      if ( callerView.$.fn.DataTable.isDataTable('#' + GRID )) {
        $('#' + GRID ).DataTable().rows().deselect();
        $('#' + GRID ).find('th.select-checkbox').parent().removeClass('selected');
      } else {
        callerView.$('#' + GRID).datagrid('clearSelections');
      }
    }
  };
  
  $('#actionModalForm').on('hidden.bs.modal', function (e) {
    $("#actionFrame").attr("src", "about:blank");
    $(".modal-header").show();
  });

  var browserName = navigator.appName;
  if (browserName == "Microsoft Internet Explorer") {
    $("#actionFrame").css("height", '100%');
    $("#actionFrame").css("width", '100%');
    $("#actionFrame").css("overflow", 'auto');
  }
  $("#actionFrame").on('load', function() {
    $(".modal-spinner").remove();
    $("#actionFrame").contents().find(".panel-body.tab-panel-body").css("height", '90vh');
    if(DISPLAY && DISPLAY.indexOf('collab') >= 0 ){
      var $container = $('#actionFrame').contents().find('.task-modal'),
      $scrollTo = $('#actionFrame').contents().find('#lastAnchor');
      if($scrollTo.offset()){
        $container.scrollTop(
            $scrollTo.offset().top - $container.offset().top + $container.scrollTop()
        );
      }
    }
  });
  
  if(URL.indexOf("?") == -1) var connector = "?";
  else  var connector = "&";
  var cachedURL = URL + connector + "cache=" + Math.random() * Math.random();
  $("#actionFrame").attr("src", cachedURL);
  $('#actionModalForm').modal({
    show: true,
  });

  //remove previous events - prevents issue where if you open and close the modal multiple times it does not build up listeners and create mulitple objects
  $(document).off('click', '.modal-confirm');

  // Modal Confirm
  $(document).on('click', '.modal-confirm', function(e) {
    e.preventDefault();
    $(this).attr('disabled','disabled');  //disable button after click to prevent multiple submissions
    handleSubmit();
  });
}

$('#actionModal').on('hidden.bs.modal', function (e) {
  $("#actionModal .action").removeClass("d-block");
})
  
function prepareUploadBox(window, uploaderconfig) {
  $(".modal-dismiss").show();
  window = window[0];

  if(uploaderconfig.ModalTitle){
    const title = document.getElementById('uploadBox').querySelector('.modal-header h2.card-title');
    if(title){
      title.textContent = uploaderconfig.ModalTitle;
    }
  }

  var handleSubmit = function() {
    $("#galleryUploader").trigger("uploadfiles");
    $("#galleryUploader").find('a').off();
  };

  var handleCancel = function() {
    $("#galleryUploader-queue").empty();
    $("#galleryUploader").find('a').off();
    closeAction();
  };

  var onUploadSuccess = function(data) {
    $("#galleryUploader-queue").empty();

    if (window.gridTest) {
      window.updateGrid();
      if (window.tree_node == top.$('#solodev-tree').tree('find', '1_' + window.asset_category_id + '_t')) {
          top.$('#solodev-tree').tree('reload', window.tree_node.target);
      }
    } else if (uploaderconfig.success) {
      uploaderconfig.success(data);
    } else if (uploaderconfig.closeActionOnUpload) {
      var activeTab = top.$('#solodev-tabs').tabs('getSelected');
      if (activeTab)
        top.$('#solodev-tabs').tabs('close', activeTab.panel('options').title);
    } else if(uploaderconfig.filemanager && data.originalFiles.length == 1){
        var json_asset_id = "";
        try{
          json_asset_id = JSON.parse(data.result).asset_file_id;
        }catch (e){
          console.log("Response from upload.php is non-json");
        }
        if(json_asset_id == "") window.document.location.reload();
        else window.document.location = "/documents/" + json_asset_id + "?filemanager=1&tab=1";
    }else{
      window.document.location.reload();
    }
    closeAction();
  };

  $('#uploadBox').modal({
    show: true,
  });
  
  if (!uploaderconfig.autoUpload) {
    uploaderconfig.autoUpload = false;
  }

  var inputAmount="";
  if (!uploaderconfig.single){
    uploaderconfig.single = false;
    inputAmount = " multiple ";
  }

  if(uploaderconfig.uploadActions){
    $('#uploadBox .modal-upload-group').show();
    //if(uploaderconfig.uploadActions.indexOf("P") != -1) //took out permission check since such granularity is no longer needed. Greg
    //$('#uploadBox .modal-footer').prepend('<button class="btn btn-color1 modal-confirm" data-action="p" type="button">Upload + Publish</button> ');
    //if(uploaderconfig.uploadActions.indexOf("B") != -1)
    //$('#uploadBox .modal-footer').prepend('<button class="btn btn-color1 modal-confirm" data-action="b" type="button">Upload + Stage</button> ');
    //$('#uploadBox .modal-footer').prepend('<button class="btn btn-color1 modal-confirm" data-action="a" type="button">Upload + Save</button> ');
  }

  //HTML needs to be re-applied in order to fully reset the uploader
  var uploaderHTML = '<div id="queueContainer1"><form class="uploader" enctype="multipart/form-data" id="galleryUploader" method="post">';
  uploaderHTML += '<div id="drop"><p>Drop files here or</p><a class="btn btn-color1 btn-lg">Browse</a> <input type="file" name="Filedata" '+inputAmount+' style="display: none;" /></div>';
  uploaderHTML += '<ul id="galleryUploader-queue" class="list-unstyled"><!-- The file uploads will be shown here --></ul></form></div>';

  $("#uploadContainer").html(uploaderHTML); 

  var uploader = $("#galleryUploader").activateUploader(uploaderconfig, onUploadSuccess, uploaderconfig.autoUpload, uploaderconfig.single);

  /* Modal Dismiss */
  $('#uploadBox .modal-dismiss').off("click");
  $('#uploadBox .modal-dismiss').click(function(e) {
    e.preventDefault();
    handleCancel();
  });

  /* Modal Confirm */
  $('#uploadBox .modal-confirm').off("click");
  $('#uploadBox .modal-confirm').click(function(e) {
    e.preventDefault();
    if($('#galleryUploader-queue').children().length){
      if($(this).attr("data-action")){
        var fileState = "";
        if($(this).attr("data-action") == "p")
          fileState = "publish";
        if($(this).attr("data-action") == "b")
          fileState = "stage";
        $("#galleryUploader").data("blueimp-fileupload").options.formData.fileState = fileState;
      }
      handleSubmit();
    }else{
      alert("No Files Selected.")
      $(this).removeAttr('disabled');
    }
  });

  $('#uploadBox .modal-ok').off("click");
  $('#uploadBox .modal-ok').click(function(e) {
    e.preventDefault();
    handleCancel();
  });
}
  
//Not sure where this is being used but might be able to just call loadAction inside of loadHelper?
function loadHelper(URL) {
  $(".modal-ok").hide();
  $(".modal-confirm").show();
  $(".modal-dismiss").show();
  $('.modal-confirm').removeAttr('disabled'); //make sure the confirm is reactivated
  $("#actionModalForm").find("footer.modal-footer").show();
  utils.setWait();
  var handleSubmit = function() {
    //alert("trying to submit");
    for (var i = 0; i < window.frames.length; i++) {
      if (window.frames[i].name == 'actionFrame') {
        break;
      }
    }
    formExists = false;
    for (var ii = 0; ii < window.frames[i].document.forms.length; ii++) {
      if ($(window.frames[i].document.forms[ii]).attr("name") == 'contentForm' || $(window.frames[i].document.forms[ii]).attr("id") == 'contentForm') {
        formExists = 1;
        break;
      }
    }
    //alert(formExists);
    if (formExists) {
      if (typeof window.frames[i].onsubmit == "function") {
        window.frames[i].onsubmit();
      }

      window.frames[i].document.forms[ii].submit();
      //Clear Focus
    } else if (window.frames[i].gridTest) {
      //alert("grid");
      var rows = window.frames[i].$('#' + window.frames[i].gridTest).datagrid('getSelections');
      for (var object_id in rows[0]) {
        if (object_id.indexOf(".") != -1) {
          var object_key = object_id.split(".");
          object_id = object_key[1];
          object_id = object_key[0];
        }
        break;
      }
      var function_name = "f_" + window.frames[i].mapped_object + "_" + window.frames[i].mapped_parent + "_addObjectMap";
      for (var iii = 0; iii < rows.length; iii++) {
        //var_dump(rows[iii]);
        currentTab.contentWindow[function_name](window.frames[i].mapped_object + "_" + window.frames[i].mapped_parent + "_" + rows[iii].id, rows[iii].name);
      }
      closeAction();
    } else {
      //alert("nothing");
    }
  };

  var handleCancel = function() {
    $("#helperFrame").attr("src", "about:blank");
    $('#actionModalForm .modal-dialog, #objChooserModal .modal-dialog').removeClass('modal-lg'); 
    //$("#helper").dialog("close");
    $.magnificPopup.close();
  };

  /*  Modal Dismiss  */
  $('#actionModalForm .modal-dismiss').off("click");
  $('#actionModalForm .modal-dismiss').click(function(e) {
    //e.preventDefault();
    handleCancel();
  });

  /* Modal Confirm */
  $('#actionModalForm .modal-confirm').off("click");
  $('#actionModalForm .modal-confirm').click(function(e) {
    e.preventDefault();
    handleSubmit();
  });

  $('#actionModalForm .modal-ok').off("click");
  $('#actionModalForm .modal-ok').click(function(e) {
    //e.preventDefault();
    handleCancel();
  });

  var browserName = navigator.appName;
  if (browserName == "Microsoft Internet Explorer") {
    $("#actionFrame").css("height", '100%');
    $("#actionFrame").css("width", '100%');
    $("#actionFrame").css("overflow", 'auto');
  }

  $("#actionFrame").on('load', function() {   //there was padding in the header so outerHeight had to be used. Tell Greg before editing.
    var modal_height = $("#actionFrame").height();
    var header_height = $("#actionFrame").contents().find("header").outerHeight();
    // $("#actionFrame").contents().find(".panel-body.tab-panel-body").css("height", modal_height - header_height);
  });

  if(URL.indexOf("?") == -1) var connector = "?";
  else  var connector = "&";

  var cachedURL = URL + connector + "cache=" + Math.random() * Math.random();
  $("#actionFrame").attr("src", cachedURL);

  utils.closeWait();
}

$(document).on('click', '#objChooserModal .btn-choose', function (event) {

  var chooserModal = top.$('#objChooserModal');
  var opener_id = chooserModal.find('.btn-choose').data("opener");
  var opener_parent = chooserModal.find('.btn-choose').data("openerparent"); //right now only experiment
  var actionModal = top.$('#actionFrame').contents();
  var displayCaller = opener_id+"_display";
  var on_page_chooser = top.$('.manager_folder_location');
  
  if(chooserModal.find('#object_name').val() == "") {
    var fieldValue = "";
    var fieldName = "";
    var fieldRef = "";
  }else{ 
    var fieldValue = chooserModal.find('#object_id').val();
    var fieldName = chooserModal.find('#object_name').val();
    var fieldRef = chooserModal.find('#my_module_object_id').val();
  }
  
  if(on_page_chooser.length > 0){
    on_page_chooser.find('#'+opener_id).val(fieldValue);
    on_page_chooser.find('#'+displayCaller).val(fieldName);
    on_page_chooser.find('#my_module_object_id').val(fieldRef);
  } else if(opener_parent){
    chooserModal.find('.btn-choose').data("openerparent","");  //clear opener value
    top.$("iframe[src*='experiment_id="+opener_parent+"']").contents().find('#'+opener_id).val(fieldValue);
    top.$("iframe[src*='experiment_id="+opener_parent+"']").contents().find('#'+displayCaller).val(fieldName);
  }else{
    actionModal.find('#'+opener_id).val(fieldValue);
    actionModal.find('#'+displayCaller).val(fieldName);
    actionModal.find('#my_module_object_id').val(fieldRef);
  }
  chooserModal.find('.btn-choose').data("opener","");  //clear opener value
  chooserModal.modal('hide');
});

/* Start Solodev Filemanager Chooser Modal */
$(document).ready(function(){
  $("a.filemanager").click(function(e){  //a.filemanager added by Form_Parser.inc
    e.preventDefault();
    var manager_module_id = $(this).data('managerMod');
    var manager_id = $(this).data('managerId');
    var parentfldr = $("input[name='underscore_asset_category_id']").val();
    if(!parentfldr || parentfldr==0) parentfldr = $("input[name='parent_category_id']").val();
    var URL="/filemanager?type=1&editor=solodev_manager&fldr="+parentfldr+"&CKEditor=post_content&CKEditorFuncNum=0&langCode=en&mngmod="+manager_module_id+"&mngid="+manager_id+"&fieldid="+$(this).data("location");
    var w = $(window.top).width()*.8;
    var h = $(window.top).height()*.7;
    var left = ($(window.top).width()*.5)-(w*.5);
    var top = ($(window.top).height()*.5)-(h*.5);
    window.open(URL, "filemanager", "titlebar=no,menubar=no,height="+h+",width="+w+",top="+top+",left="+left+"");
  });

  $("a[data-type='FMC']").click(function(){  // close/cancel filemanager modal
    top.window.close();
  });

  // Helper function to get parameters from the query string.
  function getUrlParam( paramName ) {
      var reParam = new RegExp( '(?:[\?&]|&)' + paramName + '=([^&]+)', 'i' );
      var match = window.location.search.match( reParam );

      return ( match && match.length > 1 ) ? match[1] : null;
  }
  // Simulate user action of selecting a file to be returned to CKEditor.
  function returnFileUrl(filename, file_id, parent_folder) {

      var editor_type = getUrlParam( 'editor' );

      if(editor_type == "solodev_manager"){
          var field_id = getUrlParam( 'fieldid' );
          window.opener.solodevUtils.setbrowseServerValue(filename,file_id,field_id);
          window.opener.$("#"+field_id).trigger('change');
      } else {
          var funcNum = getUrlParam( 'CKEditorFuncNum' );
          var fileUrl = '/core/fileparse.php/'+parent_folder+'/urlt/'+filename;

          window.opener.CKEDITOR.tools.callFunction( funcNum, fileUrl, function() {
              // Get the reference to a dialog window.
              var dialog = this.getDialog();
              // Check if this is the Image Properties dialog window.
              if ( dialog.getName() == 'image' ) {
                  // Get the reference to a text field that stores the "alt" attribute.
                  var element = dialog.getContentElement( 'info', 'txtAlt' );
                  // Assign the new value.
                  if ( element )
                      element.setValue( 'alt text' );
              }
              // Return "false" to stop further execution. In such case CKEditor will ignore the second argument ("fileUrl")
              // and the "onSelect" function assigned to the button that called the file manager (if defined).
              // return false;
          });
      }
      window.close();
  }

  $("button[data-type='FM']").click(function(){
    values = $("iframe#filemanager")[0].contentWindow.getSelectedFile();
    returnFileUrl(values.filename, values.fileid, values.parent_folder);
  });
});

function setbrowseServerValue(asset_name, asset_id, field_id){
  $("#group-"+field_id).find("input[type=hidden]").val(asset_id);
  $("#"+field_id+"-browse-name").remove();
  $("#group-"+field_id).find("input[type=hidden]").before('<span id="'+field_id+'-browse-name" class="ml-2">'+asset_name+'</span>');
  $("#group-"+field_id).find("input[type=hidden]").next().hide();

  $("#"+field_id).val(asset_id);
  $("#"+field_id).before('<span id="'+field_id+'-browse-name" class="ml-2">'+asset_name+'</span>');
  $("#"+field_id).next().hide();

}

utils.addToGlobal({
  closeAction,
  loadAction,
  prepareUploadBox,
  setbrowseServerValue,
});