/**
* Jquery
*/

import 'jquery';
import 'jquery-validation';
import 'jquery-ui';
import 'jquery-knob';
import 'jquery-form';

import 'moment-timezone';
import 'chosen-js';
import 'sortablejs';
import 'typeahead.js';
import 'magnific-popup';
import 'nanoscroller';
/**
* Bootstrap
*/
import 'popper.js';
import 'bootstrap-tokenfield';
import 'bootstrap';
import 'pc-bootstrap4-datetimepicker';
import 'bootstrap-colorpicker';

import 'blueimp-file-upload';

/**
* Components
*/
import './js/portal';
import './js/editor';
import './js/modal';
import './js/multitabs';
import './js/fileuploader';
import 'ckeditor4'
import 'ckeditor4/adapters/jquery'
import './js/main';

// DATATABLES.NET
//import 'datatables.net';
//import dt from 'datatables.net-bs4';
//dt(window, $);
import 'datatables.net-colreorder-bs4';
import 'datatables.net-keytable-bs4';
import 'datatables.net-responsive-bs4';
import 'datatables.net-rowgroup-bs4';
import 'datatables.net-rowreorder-bs4';
import 'datatables.net-scroller-bs4';
import 'datatables.net-select-bs4';

import './scss/app.scss';

import ObjectChooser from './js/components/modal/object-chooser';
import ActionBar from './js/components/layout/layout-action-bar';
import LayoutMenu from './js/components/layout/layout-menu';
import Pagination from './js/components/pagination';
import Search from './js/components/search';
import Images from './js/components/images';

$(function() {
  new ObjectChooser();
  new ActionBar();
  new LayoutMenu();
  new Pagination();
  new Search();
  new Images();
});

/*
removing for now due to IE incompatibility
//CKEDITOR 5
import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapterPlugin from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';

export default class ClassicEditor extends ClassicEditorBase {}

var classicSettings = {
  plugins: [
    EssentialsPlugin,
    UploadAdapterPlugin,
    AutoformatPlugin,
    BoldPlugin,
    ItalicPlugin,
    BlockQuotePlugin,
    EasyImagePlugin,
    HeadingPlugin,
    ImagePlugin,
    ImageCaptionPlugin,
    ImageStylePlugin,
    ImageToolbarPlugin,
    ImageUploadPlugin,
    LinkPlugin,
    ListPlugin,
    ParagraphPlugin,
    PasteFromOffice
  ],
  toolbar: [
    'heading',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'imageUpload',
    'blockQuote',
    'undo',
    'redo','Source','-','Preview','Print','-','Templates' 
  ],
  image: {
    toolbar: [
      'imageStyle:full',
      'imageStyle:side',
      '|',
      'imageTextAlternative'
    ]
  }
}

//create our own jquery function to call ckeditor
$.fn.ckeditor5 = function(optionsIn) {
  
  var options = optionsIn || {};
  
  //check if destroy editor
  if(options.destroy){
    var editor = $(this).data('editor');
    editor.destroy();
    return;
  }
  
  var editorSettings = classicSettings;
  //check options to change settings
  
  
  //initialize the editor
  ClassicEditor
  .create( this[0], editorSettings)
  .then( editor => {
    $(this).data('editor', editor);
    //console.log( editor );
  })
  .catch( error => {
    console.error( error );
  });
};
*/