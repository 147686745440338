export default class LayoutMenu {
  
  constructor() {
    const addPageBtn = document.querySelector('[data-name="Add Page"]');

    if (addPageBtn) {
      this.addPageBtn = addPageBtn;
      this.addPageModal();
    }
  }
  addPageModal() {
    this.addPageBtn.addEventListener('click', () => {
      const {name, icon, route} = this.addPageBtn.dataset;
      top.solodevUtils.loadAction(route, '', name, icon, '');
    });
  }
}