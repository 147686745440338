import moment from 'moment';
import echo from './vendor/echo';
import Bloodhound from 'bloodhound-js';
import noUiSlider from 'nouislider';

/**
 * Codemirror
 */
import CodeMirror from 'codemirror';
import 'codemirror/mode/javascript/javascript' 
import 'codemirror/addon/fold/foldcode' 
import 'codemirror/addon/edit/matchbrackets' 
import 'codemirror/addon/dialog/dialog' 
import 'codemirror/addon/search/searchcursor' 
import 'codemirror/addon/scroll/annotatescrollbar' 
import 'codemirror/addon/search/matchesonscrollbar' 
import 'codemirror/addon/search/match-highlighter' 
import 'codemirror/addon/search/search' 
import 'codemirror/addon/merge/merge' 
import 'codemirror/addon/display/autorefresh' 

import { RelationshipParsing, saveRelationships } from './relationships';
import { SchemaParsing, saveSchema } from './schema';

/* ================================  
  # Random color functionality
================================ */
export const initialsToColor = function(str) {
  str = String(str);
  var a = str.charCodeAt(0);
  var b = str.charCodeAt(1);
  var hash = (a*b*(a+b)).toString(16).substring(0, 6);
  hash = hash.split("").reverse().join("");
  if(hash.length === 5) {
    var color = ('#'+hash+'0');
    return color;
  }
  if(hash.length < 5) {
    return false;
  }
  var color = ('#'+hash);
  return color;
}

/* ================================  
  # Codemirror
================================ */
export const applyCodemirror = ( htmlelement ) => {
  const cmeditor = CodeMirror.fromTextArea(htmlelement, {
    mode: "javascript",
    lineNumbers: true,
    lineWrapping: true,
    styleActiveLine: true,
    matchBrackets: true,
    enableCodeFolding: true,
    enableCodeFormatting: true,
    electricChars:false,
    highlightSelectionMatches: true,
    smartIndent: false,
    autoRefresh: true
  });
  var keymap = {"Ctrl-F": "findPersistent"}
  cmeditor.addKeyMap(keymap);

  var refreshTime_cm = 0;

  cmeditor.on("keydown", function () {
    var nowTime_cm = parseInt(Math.round(new Date().getTime() / 1000));
    var timeCheck_cm = parseInt(nowTime_cm - refreshTime_cm);

    if(timeCheck_cm > 10 ){ //can be increased or descreased. just trying to limit the number of ajax calls.
      refreshTime_cm = nowTime_cm;
      //console.log('adsf');
      // $.ajax({url: "/api.php/refresh", success: function(result){ }}); 
      $.ajax({url: "/api/refresh", success: function(result){ }}); 
    }
  });

  return cmeditor;
}


/* ================================  
  # Action Window
================================  */
export const closeHelper = () => {
  $("#helperFrame").attr("src", "about:blank");
  //$("#helper").dialog("close");
  closeWait();
}

export const enableSubmit = () => {
	$('.modal-confirm').removeAttr('disabled');
}

var activeWait = [];
var actionLoaded = false;

export function setWait() {
  activeWait[activeWait.length] = 1;
  if (activeWait.length == 1) {
    $("#wait").dialog({
      resizable: false,
      show: 'fade',
      hide: 'fade',
      title: "Loading, please wait...",
      modal: true
    });

    $("#wait").dialog({
      show: 'fadeIn'
    });

    $("#wait").css("height", 100);
    $("#wait").css("width", 230);

    addToGlobal({
      activeWait,
    });
  }
}

function arraySlice(array, from, to) {
  var rest = array.slice((to || from) + 1 || array.length);
  return rest;
}

export function closeWait() {
  //Test to see if any wait functions have been called
  if (activeWait[0]) {
    activeWait = arraySlice(activeWait, activeWait.length, activeWait.length);
    //alert("remove slice");
    if (activeWait.length == 0) {
      $("#wait").dialog("close");
    }
    addToGlobal({
      activeWait,
    });
  }
}

/**
* Add a URL parameter 
* @param {string} url 
* @param {string} param the key to set
* @param {string} value 
*/
export const addParam = function(url, param, value) {
  var a = document.createElement('a');
  param += (value ? "=" + value : ""); 
  a.href = url;
  if(a.search.indexOf(param) == -1){
    a.search += (a.search ? "&" : "") + param;
  }
  return a.href;
}

export const urldecode = ( url ) => decodeURIComponent(url.replace(/\+/g, ' '));
export const function_exists = ( function_name ) => eval('window.parent.typeof ' + function_name) === 'function';

export const addToGlobal = ( props ) => {
  window.solodevUtils = {
    echo,
    moment,
    Bloodhound,
    RelationshipParsing,
    saveRelationships,
    SchemaParsing,
    saveSchema,
    CodeMirror,
    noUiSlider,
    ...window.solodevUtils,
    ...props
  }
}