export default class ActionBar {
  
  constructor() {
    const editStmlButton = document.getElementById('edit-stml'),
    expandToFullScreenBtn = document.getElementById('expand-btn'),
    resizeActions = document.querySelectorAll('[data-action="resize"]'),
    toggleMeta = document.getElementById('toggle-menu'),
    previewSite = document.getElementById('site-preview-frame'),
    showDynamicDiv = document.getElementById('show-dynamic-div'),
    taskBtn = document.getElementById('task'),
    overwriteBtn = document.getElementById('overwrite'),
    iFrameContent = parent.document.querySelector('iframe[id="multitabs_info_1"]');

    if (editStmlButton) {
      this.editStmlButton = editStmlButton;
      this.editStml();
    }
    if (resizeActions.length) {
      this.resizeActions = resizeActions;
      this.resizeScreenView();
    }
    if (expandToFullScreenBtn) {
      this.expandToFullScreenBtn = expandToFullScreenBtn;
      this.expandToFullScreen();
    }
    if (toggleMeta) {
      this.toggleMeta = toggleMeta;
      this.toggleMetaColumn();
    }
    if (iFrameContent) {
      this.iFrameContent = iFrameContent;
      this.activeTab();
    }
    if (previewSite) {
      this.previewSite = previewSite;
      this.previewSiteImage();
    }
    if (showDynamicDiv) {
      showDynamicDiv.addEventListener('click', function() {
        window.solodevUtils.showDD();
      });
    }
    if (taskBtn) {
      this.taskBtn = taskBtn;
      this.taskModal();
    }
    if (overwriteBtn) {
      this.overwriteBtn = overwriteBtn;
      this.overwriteFile();
    }
  }
  editStml() {
    const pageIndexId = this.editStmlButton.dataset.index;

    this.editStmlButton.addEventListener('click', (e)=> {
      e.preventDefault();
      top.openTab('/documents/'+ pageIndexId, 'index.stml', '2_'+ pageIndexId +'_t');
    });
  }
  resizeScreenView() {
    this.resizeActions.forEach(btn => {
      btn.addEventListener('click', this.pagePreviewScreen.bind(this, btn));
    });
  }
  pagePreviewScreen(btn) {
    const {action, view} = btn.dataset,
    DashboardIframe = (window.parent.document.querySelector('iframe.active'))
                          ? window.parent.document.querySelector('iframe.active').contentWindow.document.querySelector('#site-preview-frame')
                          : window.parent.document.querySelector('#content-tab').querySelector('iframe'),
    STMLIframe = (window.parent.document.querySelector('iframe.active'))
                          ? window.parent.document.querySelector('iframe.active').contentWindow.document.querySelector('#STML_frame')
                          : window.parent.document.querySelector('#content-tab').querySelector('iframe'),
    getIframeSize = (view) => ({
      'mobile': 'w-xs-410p h-xs-740p',
      'tablet': 'h-xs-760p mw-xs-1000p w-100',
      'desktop': 'w-100 h-100',
    }[view]);

    if (DashboardIframe) {
      DashboardIframe.className = getIframeSize(view) + ' d-block mx-auto';
    }
    if (STMLIframe) {
      STMLIframe.className = getIframeSize(view) + ' d-block mx-auto';
    }
  }
  expandToFullScreen() {
    this.expandToFullScreenBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const iconFullscreen = this.expandToFullScreenBtn.querySelector('span'),
      divWrapper = window.parent.document.querySelector('#solodev-tabs > .mt-wrapper'),
      divActiveTab = window.parent.document.querySelector('iframe.active'),
      divContentTab = document.querySelectorAll('.content-tab');

      iconFullscreen.classList.toggle('fa-expand-alt');
      iconFullscreen.classList.toggle('fa-compress-alt');
      if (divContentTab.length) {
        divContentTab.forEach(content => {
          content.classList.toggle('full-screen');
          if (content.parentElement.classList.contains('content-dashboard')) {
            content.classList.toggle('col-8');
          } else {
            content.classList.toggle('col-9');
          }
        });
      }
      if (divWrapper) {
        divWrapper.classList.toggle('full-screen');
      }
      if (divActiveTab) {
        divActiveTab.classList.toggle('full-screen');
      }
    });
  }
  previewSiteImage() {
    document.addEventListener('DOMContentLoaded', function () {
      this.sitePreview.addEventListener('load', function() {
        const iframePreview = this.sitePreview.contentDocument || this.sitePreview.contentWindow.document;
        if (iframePreview) {
          const iframeBody = iframePreview.querySelector('body');
          if (iframeBody) {
            iframeBody.classList.add('overflow-auto');
          }
        }
      });
    });
  }
  toggleMetaColumn() {
    const metaColumnBar = document.querySelector('.content-bar .meta-column'),
    btnCollapseLeft = document.getElementById('toggle-menu-left'),
    contentBar = document.querySelector('.content-bar .content-tab'),
    contentBody = document.querySelector('.content-body .content-tab'),
    contentDashboard = document.querySelector('.content-dashboard'),
    toggleMeta = document.getElementById('toggle-menu');

    toggleMeta.addEventListener('click', () => {
      
      btnCollapseLeft.classList.remove('d-none');
      metaColumnBar.classList.add('collapsed');

      contentBar.classList.add('col-12');
      contentBody.classList.add('col-12');
      if (contentDashboard) {
        contentBar.classList.remove('col-8');
        contentBody.classList.remove('col-8');
      } else {
        contentBar.classList.remove('col-9');
        contentBody.classList.remove('col-9');
      }
    });
    btnCollapseLeft.addEventListener('click', () => {

      btnCollapseLeft.classList.add('d-none');
      metaColumnBar.classList.remove('collapsed');

      contentBar.classList.remove('col-12');
      contentBody.classList.remove('col-12');
      if (contentDashboard) {
        contentBar.classList.add('col-8');
        contentBody.classList.add('col-8');
      } else {
        contentBar.classList.add('col-9');
        contentBody.classList.add('col-9');
      }

    });
  }
  activeTab() {
    const activeTab = window.parent.document.querySelector('li.active').querySelector('a');
    if (activeTab) {
      activeTab.addEventListener('click', () => {
        this.iFrameContent.src = this.iFrameContent.src;
      });
    }
  }
  taskModal() {
    this.taskBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const { object, file, name } = this.taskBtn.dataset;
      top.solodevUtils.loadAction('/tasks/'+ object +'/'+ file +'', '', 'Tasks' + name, 'fas fa-lg fa-user-friends', 'large-collab'); 
    }); 
  } 
  overwriteFile() {
    const { file, client, csrfname, csrfvalue } = this.overwriteBtn.dataset;

    const uploaderConfig = {
      'single': true, 
      'asset_category_id': 0, /* hack to skip check for uploaded files having same name */
      'asset_file_id': file,
      'client': client, 
      'closeActionOnUpload': 0, 
      'uploadActions': 'M',
      'csrf_name': csrfname,
      'csrf_value': csrfvalue
    };
    this.overwriteBtn.addEventListener('click', (e)=> {
      e.preventDefault();
      top.$('#galleryUploader input[name="Filedata"]').removeAttr('multiple');
      uploaderConfig.ModalTitle = e.currentTarget.title;
      uploaderConfig.single = true;
      top.solodevUtils.prepareUploadBox($(window), uploaderConfig);
    });
  }
}