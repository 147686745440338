/* Add here all your JS customizations */
utils.addToGlobal({
  ...utils,
})
/* ================================  
  # Toggle dropdowns - (Elena R.)
================================ */
var toClose = false;

function dropdownToggle(e) {
  e.stopPropagation();
  var btn = this;
  var menu = btn.nextSibling;

  while(menu && menu.nodeType != 1) {
    menu = menu.nextSibling;
  }

  // console.log(menu);
  if (!menu) return;
  if (menu.style.display !== 'block') {
    menu.style.display = 'block';
    if(toClose) menu.style.display="none";
    toClose  = menu;
  }  else {
    menu.style.display = 'none';
    toClose = false;
  }
};

function closeAll() {
   toClose.style.display='none';
   toClose = false;
};

// window.addEventListener("DOMContentLoaded",function(){
//   var user_button = document.querySelectorAll(".user-dropdown");
//   for(var i=0; i<user_button.length; i++){
//     user_button[i].addEventListener("click", dropdownToggle, true);
//   }
// });

window.onclick = function(event){
  if (toClose){
    closeAll.call(event.target);
  }
};

var currentTab;

/* ================================  
  # Panels from theme.js
================================ */
(function ($) {

  $(function () {
    $('.panel')
      .on('panel:toggle', function () {
        var $this,
        direction;

        $this = $(this);
        direction = $this.hasClass('panel-collapsed') ? 'Down' : 'Up';

        $this.find('.panel-body, .panel-footer')[ 'slide' + direction ](200, function () {
          $this[ (direction === 'Up' ? 'add' : 'remove') + 'Class' ]('panel-collapsed')
        });
      })
      .on('panel:dismiss', function () {
        var $this = $(this);

        if (!!($this.parent('div').attr('class') || '').match(/col-(xs|sm|md|lg)/g) && $this.siblings().length === 0) {
          $row = $this.closest('.row');
          $this.parent('div').remove();
          if ($row.children().length === 0) {
            $row.remove();
          }
        } else {
          $this.remove();
        }
      })
      .on('click', '[data-panel-toggle]', function (e) {
        e.preventDefault();
        $(this).closest('.panel').trigger('panel:toggle');
      })
      .on('click', '[data-panel-dismiss]', function (e) {
        e.preventDefault();
        $(this).closest('.panel').trigger('panel:dismiss');
      })
      /* Deprecated */
      .on('click', '.panel-actions a.fa-caret-up', function (e) {
        e.preventDefault();
        var $this = $(this);

        $this
          .removeClass('fa-caret-up')
          .addClass('fa-caret-down');

        $this.closest('.panel').trigger('panel:toggle');
      })
    .on('click', '.panel-actions a.fa-caret-down', function (e) {
      e.preventDefault();
      var $this = $(this);

      $this
        .removeClass('fa-caret-down')
        .addClass('fa-caret-up');

      $this.closest('.panel').trigger('panel:toggle');
    })
    .on('click', '.panel-actions a.fa-times', function (e) {
      e.preventDefault();
      var $this = $(this);

      $this.closest('.panel').trigger('panel:dismiss');
    })
    .on('click', 'header.panel-heading', function (e) {  //Greg - make the whole panel clickable
      e.preventDefault();
      var $this = $(this);

      $this.closest('.panel').trigger('panel:toggle');
    });
  });
})(jQuery);

/* ================================  
  # Left Nav Resize - resizable.js (Sushma M.) - temp removed since we are not using and it interfered with datagrid resize columns
================================ */
// (function($) {
// 	$.fn.resizable = function(options) {   
// 		var element = this;
// 		var defaults = { fixedWidth : true, resizableEle: "", notResizableEle: "", innerDiv: "", id:null, changeWidth: false};
//     var opts  = $.extend( {}, defaults , options);
// 	  var isResizing = false; 
//     var lastDownX;
//     var currentDownX;
//     var drag_col;
//     var current_darghandler ; 
//     var col_width;
//     var oldMargin ;
//     var right_col_width = $(element).find('#mainArea.content').width();
// 		var dragHandler='<span class="draghandle"></span>';
//     var handlesSet ;
// 		if(opts.innerDiv != '') {
//       $(element).find(opts.resizableEle).not(opts.notResizableEle).find(opts.innerDiv).append(dragHandler);
//     }
// 		else {     
//       $(element).find(opts.resizableEle).not(opts.notResizableEle).append(dragHandler);
//     }

//     $(element).find('.nano-pane').css('display','block');

//     function dragStart() {
//        e.preventDefault();
//     }

//     function dragMove(e) {
//       e.preventDefault();       
//       window.addEventListener('mouseup', dragEnd);
//       $("iframe").contents().find("body").on('mouseup', dragEnd);
//       currentDownX = e.screenX;
//       var diff = (e.screenX-lastDownX);             
//       var x = lastDownX+diff;
//       var y = current_darghandler.offset().top;     
//       var new_width= col_width+(diff);     
//       $(drag_col).css("width",new_width+"px");     
//       $(element).find('#mainArea.content').css('marginLeft',oldMargin+(e.screenX-lastDownX)+"px");    
//       $(element).find('.tabs-panels').css("width",right_col_width-( diff ));
//       $(element).find('#mainArea *').css("width",'100%');
//       current_darghandler.offset({left: x,top: y});
//       return false;     
//     }

//     function dragEnd(e) {
//       e.preventDefault();                             
//       $(current_darghandler).removeAttr('style');                     
//       $(current_darghandler).removeClass("dragging");
//       $(element).removeClass("drag");
//       window.removeEventListener('mousemove', dragMove);
//       window.removeEventListener('mouseup', dragEnd);
//       $("iframe").contents().find("body").off('mousemove',dragMove);
//       $("iframe").contents().find("body").off('mouseup', dragEnd);       
//     }

// 		$(element).on('mousedown', '.draghandle', function(e) {   
//       alert("test");        
//       e.stopPropagation();
//       e.preventDefault();    
//       isResizing = false;       
//       current_darghandler = $(e.target); 
//       drag_col = current_darghandler.closest(opts.resizableEle);            
//       current_darghandler.addClass("dragging");
//       isResizing = true;
//       lastDownX = e.screenX;
//       $(element).addClass("drag");
//       col_width=$(drag_col).width();
//       oldMargin = parseInt($(element).find('#mainArea.content').css('marginLeft'));
//       window.addEventListener('mousemove',dragMove);    
//       $("iframe").contents().find("body").on('mousemove',dragMove);
//     });

//     $(element).find('.sidebar-toggle').on("click",function () {
//        $(drag_col).removeAttr('style');
//         $(element).find('#mainArea.content').removeAttr('style');
//     });

// 	}; 

// })(jQuery); 

/* ================================  
  # Bootstrap Toggle - theme.js
================================ */
(function ($) {

  'use strict';

  var $window = $(window);

  var toggleClass = function ($el) {
    if (!!$el.data('toggleClassBinded')) {
      return false;
    }

    var $target,
            className,
            eventName;

    $target = $($el.attr('data-target'));
    className = $el.attr('data-toggle-class');
    eventName = $el.attr('data-fire-event');


    $el.on('click.toggleClass', function (e) {
      e.preventDefault();
      $target.toggleClass(className);

      var hasClass = $target.hasClass(className);

      if (!!eventName) {
        $window.trigger(eventName, {
          added: hasClass,
          removed: !hasClass
        });
      }
      // TODO: $('#solodev-tabs').tabs() -- Move out of this file and into theme-custom.js
      $('#solodev-tabs').tabs(); // Added by CVL, kicks off JS resize of the container from EasyUI
    });

    $el.data('toggleClassBinded', true);

    return true;
  };

  $(function () {
    $('[data-toggle-class][data-target]').each(function () {
      toggleClass($(this));
    });
  });

})(jQuery);

(function ($) {
  $(function () {
    /* ================================  
      # Scrollable Navigation
    ================================ */
    $(".nano").nanoScroller();
    $('html').addClass("custom-scroll");

    /* ================================  
      # Dropdown / Search
    ================================ */
    $(".chosen-select").chosen({
      no_results_text: "Website not found!",
      width: '195px'
    });

    /* ================================  
      # Datepicker
    ================================ */
    $('input[name="formfields\\[start_time\\]"]').datetimepicker({format: 'MM/DD/YYYY hh:mm A', widgetPositioning: {vertical: 'bottom'}, icons: {time: "far fa-clock", date: "far fa-calendar-alt"}}).attr('autocomplete','off');
    $('input[name="formfields\\[end_time\\]"]').datetimepicker({format: 'MM/DD/YYYY hh:mm A', widgetPositioning: {vertical: 'bottom'}, icons: {time: "far fa-clock", date: "far fa-calendar-alt"}}).attr('autocomplete','off');
    $('#datetimepicker1').datetimepicker({format: 'MM/DD/YYYY hh:mm A', widgetPositioning: {vertical: 'bottom'}, icons: {time: "far fa-clock", date: "far fa-calendar-alt"}}).attr('autocomplete','off');
    $('#datetimepicker2').datetimepicker({format: 'MM/DD/YYYY hh:mm A', widgetPositioning: {vertical: 'bottom'}, icons: {time: "far fa-clock", date: "far fa-calendar-alt"}}).attr('autocomplete','off');
    $('.datetimepicker').datetimepicker({format: 'MM/DD/YYYY hh:mm A', widgetPositioning: {vertical: 'bottom'}, icons: {time: "far fa-clock", date: "far fa-calendar-alt"}}).attr('autocomplete','off');
    $('.datedatepicker').datetimepicker({format: 'MM/DD/YYYY', widgetPositioning: {vertical: 'bottom'}, icons: {time: "far fa-clock", date: "far fa-calendar-alt"}}).attr('autocomplete','off');
    
    /* ================================  
      # Apply the wysiwyg
    ================================ */
    $('.wysiwyg').each(function () {
        $(this).ckeditor({
          customConfig: '/dist/CK/config.js',
          toolbar:'Basic',
        });
      });
      $('.wysiwyg-ep').each(function () {
        $(this).ckeditor({
          customConfig: '/dist/CK/config.js',
          toolbar:'Basic',
          emailProtection: ''
        });
      });
      $('.wysiwyg-af').each(function () {
        $(this).ckeditor(function(){
          var height_setting = $(window.top).height() - $("header.page-header").outerHeight() - $("div.fixed div[id^='action_bar']").outerHeight() - $("#solodev-tabs .mt-nav-bar",parent.document).outerHeight();
          this.resize(this.config.width, height_setting);
        },{
          customConfig: '/dist/CK/config.js',
          toolbar:'WP',
          height: '75vh'
        });
      });
      $('.wysiwyg-af-ep').each(function () {
        $(this).ckeditor(function(){
          var height_setting = $(window.top).height() - $("header.page-header").outerHeight() - $("div.fixed div[id^='action_bar']").outerHeight() - $("#solodev-tabs .mt-nav-bar",parent.document).outerHeight();
          this.resize(this.config.width, height_setting);
        },{
          customConfig: '/dist/CK/config.js',
          toolbar:'WP',
          height: '75vh', 
          emailProtection: ''
        });
      });
      $('.wysiwyg-wp').each(function () {
        $(this).ckeditor({
          customConfig: '/dist/CK/config.js',
          toolbar:'WP',
          height: '75vh'
        });
      });
      $('.wysiwyg-basic').each(function () {
        $(this).ckeditor({
          customConfig: '/dist/CK/config.js',
          toolbar:'Basic',
          height:'200px'
        });
      });
      $('.wysiwyg-basic-ep').each(function () {
        $(this).ckeditor({
          customConfig: '/dist/CK/config.js',
          toolbar:'Basic',
          height:'200px',
          emailProtection: ''
        });
      });
      $('#solodev-tabs').find('iframe #mainArea').css('margin-left', '0');
      $('.load-avatar').each(function(e){
        var bubbleColor = utils.initialsToColor($(this).data("initials"));
        $(this).css("background-color", bubbleColor);
      });
  });
})(jQuery);

/* ================================  
  # Responsive navigation
================================ */
$(document).ready(function() {
  $(document).on('click','.sidenav-open',function() {
    $('.sidebar-left').addClass('active');
    $('.sidebar-left').addClass('fadeInLeft');
    $('.sidenav-close').addClass('open');
  });
  $(document).on('click','.sidenav-close',function() {
    $('.sidebar-left').removeClass('active');
    $('.sidebar-left').removeClass('fadeInLeft');
    $('.sidenav-close').removeClass('open');
  });
});

/* ================================  
  # Popover active state
================================ */
$(document).ready(function() {
  $(document).on('click','.user-dropdown',function() {
    $('#user').toggleClass('active');
  });
  $(document).on('click','.avatar-overlay .icon',function() {
    $('#avatar').toggleClass('active');
  });
});

/* ================================  
  # Group image selector
================================ */
$(document).ready(function () {
  $('#select_avatar li img').click(function() {
    $('#select_avatar li img').not(this).removeClass('selected');
    $(this).toggleClass('selected');
    var value = $(this).data('name');
    $('#avatar').val(value);
  });
});

/* ================================  
  # Mark skills/tours as complete
================================ */
$(document).ready(function () {
  $('.skill-complete').click(function() {
    $(this).html('<i class="fas fa-check-circle fa-2x text-citron"></i>');
  });
});

/* ===================================  
  # New left navigation resizer - ER
=================================== */
$(document).ready(function () {
  var element = document.getElementById('resizable');
  if(element){
    var resizer = document.createElement('div');
    resizer.className = 'draghandle';
    resizer.style.width = '6px';
    resizer.style.height = '100vh';
    element.appendChild(resizer);
    resizer.addEventListener('mousedown', initResize, false);
  }
  function initResize(e) {
    window.addEventListener('mousemove', Resize, false);
    window.addEventListener('mouseup', stopResize, false);
    $('#mainArea.content, #solodev-tabs iframe').addClass('marginLeft');
  }
  function Resize(e) {
    element.style.width = (e.clientX - element.offsetLeft) + 'px';
    $('#mainArea.content').css('margin-left', (e.clientX - element.offsetLeft) + 'px');
    $('#solodev-tabs iframe').css('margin-left', (element.offsetLeft + 40) + 'px');
  }
  function stopResize(e) {
    window.removeEventListener('mousemove', Resize, false);
    window.removeEventListener('mouseup', stopResize, false);
    $('#solodev-tabs iframe').css('margin-left', element.offsetLeft+ 'px');
  }
});