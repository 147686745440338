export default class Images {
  
  constructor() {
    // This should be used for all uploads around the system. Will work on this programmatically.
    const inputFile = document.getElementById('social_image');
    
    if (inputFile) {
      this.inputFile = inputFile;
      this.initImageUploadName();
    }
  }

  initImageUploadName() {
    this.inputFile.onchange = (e) => {
      const inputSpan = document.querySelector('.file-name')
      inputSpan.innerText = e.target.files[0].name;
    };
  }
}