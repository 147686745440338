var lastDiv;
var linkURLs = new Object();
var linkIDNum = 0;

function disableEdit(div) {
  if (document.getElementById(div + '_editGif')) {
    document.getElementById(div + '_editGif').src = icon_path + 'clear.gif';
  }
}

function getNodeByAttribute(the_node, the_attribute, the_value) {
  var nodes = the_node.getElementsByTagName('div');
  var results = [];
  for (var i = 0; i < nodes.length; i++) {
    if (nodes[i].getAttribute(the_attribute) == the_value) {
      var results = nodes[i];
    }
  }
  return results;
}

function removeContent(div) {
  if (typeof DOMParser == "undefined") {
    DOMParser = function () {
    };
    DOMParser.prototype.parseFromString = function (str, contentType) {
      if (typeof ActiveXObject != 'undefined') {
        var d = new ActiveXObject('MSXML.DomDocument');
        d.loadXML(str);
        return d;
      } else if (typeof XMLHttpRequest != 'undefined') {
        var req = new XMLHttpRequest;
        req.open('GET', 'data:' + (contentType || 'application/xml') + ';charset=utf-8,' + encodeURIComponent(str), false);

        if (req.overrideMimeType) {
          req.overrideMimeType(contentType);
        }

        req.send(null);
        return req.responseXML;
      }
    }
  }

  setDiv = document.getElementById(div);

  if (setDiv.style.backgroundColor.indexOf('rgba(194, 220') != -1) {
    if (document.getElementById(div + '_selectGif')) {
      document.getElementById(div + '_selectGif').src = icon_path + 'selectDiv.png';
    }
    setDiv.style.backgroundColor = '';
    setDiv.parentNode.style.backgroundColor = '';
  }

  setDiv = document.getElementById(div + '_Div_Edit');
  //alert(setDiv.innerHTML);
  if (setDiv) {
    setDiv.innerHTML = '';
  }

  contentField = document.getElementById("pageContent");
  dID = setDiv.parentNode.parentNode.parentNode.parentNode.id;

  var divParents = dID.split('.');
  var divParent = 'dd.0';

  var parser = new DOMParser();
  var xmlobject = parser.parseFromString(contentField.value, 'text/xml');
  var divNode = getNodeByAttribute(xmlobject, 'id', dID);

  if (dID == 'dd.0') {
    var xml = " ";
  } else if (divNode.nodeType) {
    if (dID == 'dd.0') {
      var parent = divNode;
    } else {
      var parent = divNode.parentNode;
    }

    parent.removeChild(divNode);
    var xml = xml2Str(xmlobject);
  }

  if (xml || dID == 'dd.0') {
    contentField.value = xml;
    //alert(contentField.value);
  }
  currentDiv = null;
  lastDiv = div;
}

function xml2Str(xmlNode) {
  try {
    // Gecko-based browsers, Safari, Opera.
    return (new XMLSerializer()).serializeToString(xmlNode);
  } catch (e) {
    try {
      // Internet Explorer.
      return xmlNode.xml;
    } catch (e) {//Strange Browser ??
      alert('Xmlserializer not supported');
    }
  }
  return false;
}

function selectDiv(div, die) {
  domObserver.disconnect();
  if (lastDiv && (div != lastDiv)) {
    if (document.getElementById(lastDiv + '_selectGif')) {
      document.getElementById(lastDiv + '_selectGif').src = icon_path + 'selectDiv.png';
    }
    setDiv = document.getElementById(lastDiv);
    if (setDiv) {
      setDiv.style.backgroundColor = '';
      setDiv.parentNode.style.backgroundColor = '';
      //setDiv.parentNode.style.zIndex = 0;
    }
  }

  if (div == lastDiv) {
    $('.infoDivs').each(function () {
      $(this).removeClass('menuOn');
      $(this).fadeOut('fast');
      $('.pathLink').hide();
      $('.rmContents').hide();
      $('.ckLink').hide();
    });
    lastDiv = null;
  }
  
  setDiv = document.getElementById(div);
  if (setDiv) {
    if (setDiv.style.backgroundColor == '#1e90ff' || setDiv.style.backgroundColor.indexOf('rgba(194, 220') != -1) {
      if (document.getElementById(div + '_selectGif')) {
        document.getElementById(div + '_selectGif').src = icon_path + 'selectDiv.png';
      }

      setDiv.style.backgroundColor = '';
      //$(setDiv).find(".dynamicDiv").css('z-index', '').css('position', "inherit");  //not sure if needed any more?
      currentDiv = null;
      
      
      lastClicked = null;
      
    } else if (!die) {
      if (document.getElementById(div + '_selectGif')) {
        document.getElementById(div + '_selectGif').src = icon_path + 'selectDiv.png';
      }

      setDiv.style.backgroundColor = 'rgba(194, 220, 249, .2)';
      //$(setDiv).find(".dynamicDiv").css('z-index', -2).css('position', "relative");  //not sure if needed any more?
      currentDiv = div + '_Div_Edit';
      lastDiv = div;
    }
  }
  utils.addToGlobal({
    currentDiv,
  });
}

var wysiwyg;

function editDiv(div, module_object_id, object_id, permissions, edit_inline) {
  domObserver.disconnect();
  var contentdiv = document.getElementById(div);
  $(contentdiv).parent().attr('data-edited', 1);
  $(contentdiv).parent().attr('data-module-object-id', module_object_id);
  $(contentdiv).parent().attr('data-object-id', object_id);
  $(contentdiv).parent().attr('data-permissions', permissions);
  $(contentdiv).parent().attr('data-edit-inline', edit_inline);
  $(contentdiv).find('.infoDivs').fadeOut('fast');
  $(contentdiv).find('.menu-solodev').hide();

  var editDiv = document.getElementById(div + '_Div_Edit');

  editDiv.contentEditable = true;

  wysiwyg = CKEDITOR.inline(editDiv, {
    fillEmptyBlocks: false,
    startupFocus: true,
    //filebrowserUploadUrl: '/CK/upload/upload.php'+uploadVars,
    customConfig: '/dist/CK/config_inline.js'
  });

  //add draft/stage/publish buttons
  wysiwyg.addCommand('draft', {// create named command
    exec: function (edt) {
      wysiwyg.destroy(false); //close editor
      var dd = $(contentdiv).closest('.dynamicDiv')[0];
      var contents = getDDRawContent(dd);
      updateObject(module_object_id, object_id, contents, 'update');
    }
  });

  wysiwyg.addCommand('stage', {// create named command
    exec: function (edt) {
      wysiwyg.destroy(false); //close editor
      var dd = $(contentdiv).closest('.dynamicDiv')[0];
      var contents = getDDRawContent(dd);
      updateObject(module_object_id, object_id, contents, 'stage');
    }
  });

  wysiwyg.addCommand('publish', {// create named command
    exec: function (edt) {
      wysiwyg.destroy(false); //close editor
      var dd = $(contentdiv).closest('.dynamicDiv')[0];
      var contents = getDDRawContent(dd);
      updateObject(module_object_id, object_id, contents, 'publish');
    }
  });

  if (permissions && permissions.indexOf('M') != -1) {
    wysiwyg.ui.addButton('Draft', {// add new button and bind our command
      label: 'Draft',
      command: 'draft',
      icon: '/dist/images/icons/save.png'
    });
  }

  if (permissions && permissions.indexOf('B') != -1) {
    wysiwyg.ui.addButton('Stage', {// add new button and bind our command
      label: 'Stage',
      command: 'stage',
      icon: '/dist/images/icons/stage.png'
    });
  }

  if (permissions && permissions.indexOf('P') != -1) {
    wysiwyg.ui.addButton('Publish', {// add new button and bind our command
      label: 'Publish',
      command: 'publish',
      icon: '/dist/images/icons/publish.png'
    });
  }

  wysiwyg.on('instanceReady', function () {
    $('.cke_button__publish_label').css('display', 'inline');
    $('.cke_button__stage_label').css('display', 'inline');
    $('.cke_button__draft_label').css('display', 'inline');
    $('#'+ div.replace(/\./g, '\\.') + ' a.closeEditorLink').show();
  });

  //console.log("plugins",CKEDITOR.plugins);
  wysiwyg.on('blur', function (e) {
    wysiwyg.destroy(false);
  });

  wysiwyg.on('destroy', function (e) {
    $('a.closeEditorLink').hide();
    ckediting = false;
    wysiwyg = null;
    $('#' + div.replace(/\./g, '\\.') + ' > .infoDivs').fadeIn('fast');
    editDiv.contentEditable = false;
    //document.getElementById(div).style.backgroundColor = 'rgba(30, 144, 255, 0.2)';
    document.getElementById(div).style.backgroundColor = 'rgba(194, 220, 249, 0.2)';
  });
  ckediting = true;
}

function beforeEditorSubmit() {
  //check for all edited divs and save them accordingly
  var stmlWindow;
  var stmlFrame;

  if (document.getElementById('STML_frame')) {
    stmlWindow = document.getElementById('STML_frame').contentWindow;
    stmlFrame = document.getElementById('STML_frame').contentWindow.document;
  } else {
    stmlWindow = window;
    stmlFrame = document;
  }

  if (stmlWindow.CKEDITOR) {
    for (name in stmlWindow.CKEDITOR.instances) {
      //stmlWindow.CKEDITOR.instances[name].destroy(); //ckeditor on asset files were showing plain text area on publish. Is this needed? Greg 2/8/19
    }
  }

  var DDs = $('.dynamicDiv', stmlFrame);
  DDs.each(function () {
    if ($(this).attr('data-edited') == 1) {
      var contents = getDDRawContent(this);
      updateObject($(this).attr('data-module-object-id'), $(this).attr('data-object-id'), contents, document.getElementById('fileState').value);
    }
  });
}

function getDDRawContent(DD) {
  //alert(this.id);
  var contents = $(DD).find('#' + DD.id.replace(/\./g, '\\.') + '_Content_Div_Edit').clone();
  contents.find('.dynamicDiv').each(function () {
    $(DD).replaceWith($('<div class="dymamicDiv"></div>'));
  });
  contents.find('.menuJK' + DD.id.replace(/\./g, '\\.')).remove();
  return contents.html();
}

function updateObject(module_object_id, object_id, contents, filestate) {
  // var updateURL = '/api/api.php/update/' + module_object_id + '/' + object_id + '?timestamp=' + timestamp + '&nonce=' + nonce;
  var updateURL = '/api/update/' + module_object_id + '/' + object_id + '?timestamp=' + timestamp + '&nonce=' + nonce;
  if (contents) {
    $.ajax({
      url: updateURL,
      method: 'post',
      data: {
        pageContent: contents,
        fileState: filestate
      },
      success: function (data) {

      }
    });
  }
}

//after clicking the add content button, this will go and create a new asset file mirrored in web files/content and then insert it into the stml
function addNewContent() {
  var doc;

  if (window.parent) {
    doc = window.parent.document;
  } else {
    doc = document;
  }

  // var updateURL = '/api/api.php/addnewstmlcontent/' + doc.getElementById('asset_file_id').value + '?timestamp=' + timestamp + '&nonce=' + nonce;
  var updateURL = '/api/addnewstmlcontent/' + doc.getElementById('asset_file_id').value + '?timestamp=' + timestamp + '&nonce=' + nonce;
  $.ajax({
    url: updateURL,
    dataType: 'json',
    method: 'get',
    success: function (data) {
      var win = window.parent || window;
      var activeTab = top.$('#solodev-tabs').find('iframe.active')[0];
      var openUrl = '/portal?&RPC=1&tab=1&module_dm_id=1&portalProcess_1=showAssetFile&asset_file_id=' + data.asset_file_id;
      //win.open('/core/portal.stml?module_dm_id=1&portalProcess_1=showAssetFile&asset_file_id=' + data.asset_file_id, 'iLogic');
      //win.open('/portal?&RPC=1&tab=1&module_dm_id=1&portalProcess_1=showAssetFile&asset_file_id=' + data.asset_file_id, 'iLogic');
      var show_version = $('#show_version',activeTab.contentWindow.document).val();
      activeTab.contentWindow.document.getElementById('STML_frame').contentWindow.solodevUtils.RPC(openUrl + "&show_version="+show_version);
    }
  });
}

var ddReg = new Array();
var oldTime;
var activationStatus;

function activateDD() {
  activationStatus = 'active';

  var stmlFrame;

  if (document.getElementById('STML_frame')) {
    stmlFrame = document.getElementById('STML_frame').contentWindow.document;
  } else {
    stmlFrame = document;
  }

  if ($('#assetWindow').length) {
    $('#assetWindow').window('open');
  }

  var tempDivs;
  if (setDiv = stmlFrame.getElementById(window.solodevUtils.currentDiv)) {
    var dID = setDiv.parentNode.parentNode.parentNode.parentNode.id;
    tempDivs = setDiv.getElementsByTagName('DIV');
  } else {
    tempDivs = stmlFrame.getElementById('dd.0');
    if (tempDivs) {
      tempDivs = tempDivs.parentNode.getElementsByTagName('DIV');
    }
    dID = 'dd.0';
  }

  //alert("tempDivs: "+tempDivs.length);
  var dyDivCount = 0;

  if (tempDivs) {
    for (var divCount = 0; divCount < tempDivs.length; divCount++) {
      if (tempDivs[divCount].className == 'dynamicDiv' || tempDivs[divCount].id == 'dd.0') {
        var ddDiv = tempDivs[divCount];
        var curDivHTML = ddDiv.innerHTML;

        var tempDiv = stmlFrame.getElementById('ddDiv');
        //alert("tempDiv: "+tempDiv.id);
        var reg = /ddDiv/g;
        var regString = '';

        if (tempDiv != null) {
          regString = tempDiv.innerHTML;
        }

        var ddID;

        if (!tempDivs[divCount].id) {
          ddID = dID + '.' + dyDivCount;
        } else {
          ddID = tempDivs[divCount].id;
        }

        var divHTML = regString.replace(reg, ddID);
        ddDiv.innerHTML = divHTML;
        ddDiv.id = ddID;
        //$(ddDiv).css('min-height', '20px');
        if (ddDiv.id == "dd.0") {
          // $(ddDiv).css('min-height', '100%');
        }

        if (stmlFrame.getElementById(ddID + '_Content_Div_Edit')) {
          stmlFrame.getElementById(ddID + '_Content_Div_Edit').innerHTML = curDivHTML;
          if(curDivHTML == ""){
            $(ddDiv).addClass("empty-div");
          }
        }
        dyDivCount = dyDivCount + 1;

      }
    }
  }

  $('.ddControlDeactivate').css('display', '');
  $('.ddControlActivate').css('display', 'none');

  if (previousClicked) {
    toggleDiv(document.getElementById(previousClicked), 1);
  }

  deactivateLinks();
}

var domObserver;

var observeDOM = (function () {
  var MutationObserver = window.MutationObserver || window.WebKitMutationObserver, eventListenerSupported = window.addEventListener;

  return function (obj, callback) {
    if (MutationObserver) {
      // define a new observer
      var obs = new MutationObserver(function (mutations, observer) {
        if (mutations[0].addedNodes.length || mutations[0].removedNodes.length)
          callback(mutations, observer);
      });
      // have the observer observe foo for changes in children
      obs.observe(obj, {subtree: true, attributes: true, childList: true, characterData: true});
    } else if (eventListenerSupported) {
      obj.addEventListener('DOMNodeInserted', callback, false);
      obj.addEventListener('DOMNodeRemoved', callback, false);
    }
    domObserver = obs;
  };
})();

function deactivateDD() {
  activationStatus = 'deactive';
  var stmlFrame;

  if (document.getElementById('STML_frame')) {
    stmlFrame = document.getElementById('STML_frame').contentWindow.document;
  } else {
    stmlFrame = document;
  }

  if ($('#assetWindow').length) {
    $('#assetWindow').window('close');
  }

  disableClickedDivs();

  var dID;
  var tempDivs;
  if (setDiv = stmlFrame.getElementById(window.solodevUtils.currentDiv)) {
    dID = setDiv.parentNode.parentNode.parentNode.parentNode.id;
    tempDivs = setDiv.getElementsByTagName('DIV');
  } else {
    tempDivs = stmlFrame.getElementById('dd.0');
    tempDivs = tempDivs.parentNode.getElementsByTagName('DIV');
    dID = 'dd.0';
  }

  //alert('tempDivs: '+tempDivs.length);
  var dyDivCount = 0;

  for (var divCount = 0; divCount < tempDivs.length; divCount++) {
    if (tempDivs[divCount].className == 'dynamicDiv' || tempDivs[divCount].id == 'dd.0') {
      var ddDiv = tempDivs[divCount];
      var tempDiv = stmlFrame.getElementById('ddDiv');
      //alert('tempDiv: '+tempDiv.id);
      var reg = /ddDiv/g;
      var regString = '';

      if (tempDiv != null) {
        regString = tempDiv.innerHTML;
      }

      var ddID;

      if (!tempDivs[divCount].id) {
        ddID = dID + '.' + dyDivCount;
      } else {
        ddID = tempDivs[divCount].id;
      }

      $(ddDiv).attr('style', '');

      if (stmlFrame.getElementById(ddID) != null && stmlFrame.getElementById(ddID + '_Content_Div_Edit')) {
        var curDivHTML = stmlFrame.getElementById(ddID + '_Content_Div_Edit').innerHTML
        stmlFrame.getElementById(ddID).innerHTML = curDivHTML;
      }
      dyDivCount = dyDivCount + 1;
    }
  }
  $('.ddControlDeactivate').css('display', 'none');
  $('.ddControlActivate').css('display', '');
  reactivateLinks();
}

var lastEditProcess = 'updateEditor';
var liveHTML = '';
var currentDiv = '';

function finalEditorUpdate() {
  //This function should be removed
}

observeDOM(document, function (mutations, observer) {
  if(typeof jQuery !== "undefined" && typeof($) !== 'function'){
    $ = jQuery.noConflict(true);  //just in case user puts in jquery noConflict on inserted page :(
  }
  //hide inline editing capability for all dynamic divs that house anything if the javascript inside the block has been modified.
  for (var i = 0; i < mutations.length; i++) {

    if (mutations[i].target.nodeName != 'A' &&
            !$(mutations[i].target).hasClass('editLink') &&
            !$(mutations[i].target).hasClass('infoDivs') &&
            !$(mutations[i].target).children().first().hasClass('infoDivs') &&
            (!$(mutations[i].target).attr('id') || $(mutations[i].target).attr('id').indexOf('_Content_Div') == -1)
            ) {
      var DDs = $(mutations[i].target).parents('.dynamicDiv');
      if (DDs.length) {
        DDs.each(function () {
          $(this).find('a.ckLink:first').hide();
        });
      }
    }
  }
});

if(typeof jQuery !== "undefined"){
  jQuery(document).ready(function () {
    menuUpdate();
  });
}
var clicked = false;
var lastClicked = null;
var ckediting = false;
var previousClicked = null;

function menuUpdate() {
  $('div[id="dd.0"]').addClass('dynamicDiv');

  $('body').on('click', 'div[class*="dynamicDiv"]', function (e) {
    e.stopPropagation();
    toggleDiv(this);
    detectChanges(this);
  }).on('mouseleave', 'div[class*="dynamicDiv"]', function () {

    clicked = false;

    if (lastClicked) {
      previousClicked = lastClicked;
    }

    lastClicked = null;
    var parentName = $(this).attr('id');
    var parentId = parentName.split('_');
    var menu = $('div[id="menu_' + parentId[0] + '"]');

    menu.hover(function () {
      $(this).addClass('ONMENU');
      $(this).removeClass('ONMENU')
    });

    if (!menu.hasClass('ONMENU')) {
      $(this).removeClass('border-div');
      //menu.fadeOut('fast');
    }
  }).on('dblclick', 'div[class*="dynamicDiv"]', function (e) {
    e.stopPropagation();
    if (!ckediting) {
      var parentName = $(this).attr('id');
      var parentId = parentName.split('_');
      
      if (parentName != null) {
        var parentId = parentName.split('_');
        var fullContentDiv = $("div[id='" + parentId[0] + "_Content']");
        var $ckLink = fullContentDiv.find('a.ckLink');
        $ckLink.trigger("click");
      }
    }
  });
  deactivateLinks();
}

function detectChanges(element) {

  const targetDiv = element.querySelector(".dy_division");
  let changeDetected = false;

  if (targetDiv) {
    const observer = new MutationObserver(function(mutationsList, observer) {
      // Handle mutations
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {

          changeDetected = true;

        }
      }
      if(changeDetected){
        const preview_version = window.parent.document.getElementById('preview_version');
        const previewMsg = window.parent.document.getElementById('previewMsg');
        if(preview_version){
          preview_version.disabled = true;
        }
        if(previewMsg){
          previewMsg.innerHTML = "You must save your changes before previewing your page.";
        }
      }
    });

    const config = {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
      attributeOldValue: true,
      characterDataOldValue: true
    };

    observer.observe(targetDiv, config);

  } else {
    console.error('Target div not found inside the iframe.');
  }

}

function toggleDiv(dd, forceRedo) {
  if (!ckediting) {
    clicked = true;
    if (lastClicked == null || forceRedo) {
      lastClicked = $(dd).attr('id');
    }

    previousClicked = lastClicked;
    
    if (clicked && lastClicked != null && lastClicked == $(dd).attr("id")) {
      //$(dd).addClass('border-div').css("border-color","#CF1919").css("background-url",icon_path+"ddBG.png");
      var parentName = $(dd).attr('id');
      var parentId = parentName.split('_');

      if (parentName != null) {
        var parentId = parentName.split('_');
        var menu = $("div[id='menu_" + parentId[0] + "']");
        //alert("div[id='menu_"+parentId[0]+"']");
        var editLink = menu.parent().find("a[class ='menuJK" + parentId[0] + " hideEdit']");
        var contentDiv = $("div[id='" + parentId[0] + "_Content_Div_Edit']");
        var fullContentDiv = $("div[id='" + parentId[0] + "_Content']");

        $('.infoDivs').each(function () {
          if ($(this).attr('id') != menu.attr('id')) {
            $(this).removeClass('menuOn');
            $(this).fadeOut('fast');
          }
        });

        $('.addLink').hide();
        $('.selectLink').hide();
        $('.pathLink').hide();
        $('.rmContents').hide();
        $('.ckLink').hide();

        $(dd).addClass('empty-div');

        if (editLink.html() != null) {
          var editHref = editLink.attr('href');
          var editTitle = editLink.html();
          var editName = editLink.data('objectName');
          var editId = editLink.data('id');
          var editPath = editLink.data('filePath');
          menu.siblings('a.pathLink').find('span.file-path').html(editName);

          if (editLink.attr('target')) {
            menu.siblings('a.pathLink').attr('target', editLink.attr('target'));
            menu.siblings('a.pathLink').click(function (e) {
              e.preventDefault();
              if (typeof window.top.openTab === "function") window.top.openTab(editHref, editName, editId);
              else top.window.location = editHref;
            });
          }

          var $ckLink = fullContentDiv.find('a.ckLink');

          if (editName == '') {
            $ckLink.addClass('d-none');
            menu.siblings('a.pathLink').addClass('d-none');
          }

          if($(dd).height() > 200){
            $ckLink.addClass('so-lg');
            // $ckLink.find("i.fas").addClass('fa-2x').removeClass('fa-lg');
            $ckLink.siblings('a.closeEditorLink').addClass('so-lg');
            $ckLink.siblings('a.closeEditorLink').find("i.fas").addClass('fa-2x').removeClass('fa-lg');
          } else {
            $ckLink.removeClass('so-lg');
            $ckLink.find("i.fas").addClass('fa-lg').removeClass('fa-2x');
            $ckLink.siblings('a.closeEditorLink').removeClass('so-lg');
            $ckLink.siblings('a.closeEditorLink').find("i.fas").addClass('fa-lg').removeClass('fa-2x');
          }

          if (editLink.data('moduleObjectId') == 2 && editLink.data('editInline') && contentDiv.find('.dynamicDiv').length == 0) {
            enableEditor($ckLink,editLink,parentId);
          } else {
            $ckLink.hide();
          }
        }

        if (contentDiv.html() != '') {
          menu.fadeIn('fast');
          menu.addClass('menuOn');
          menu.css('display', 'block');

          menu.parent().find('.addLink').hide();
          menu.parent().find('.selectLink').hide();
          menu.siblings('a.pathLink').show();
          menu.siblings('.rmContents').show();
        } else {
          menu.parent().find('.addLink').show();
          if(menu.parent().attr("id") != "dd.0_Content") menu.parent().find('.selectLink').show();
          else menu.parent().find('.selectLink').hide();
          menu.parent().find('a.pathLink').hide();
          menu.parent().find('.rmContents').hide();

          menu.parent().find('a.addLink').off(); //clear listeners
          menu.parent().find('a.selectLink').off(); //clear listeners
          menu.parent().find('a.pathLink').off(); //clear listeners
          menu.parent().find('.rmContents').off(); //clear listeners

          menu.parent().find('a.addLink').click(function (event) {
            event.preventDefault();
            event.stopPropagation();
            addNewContent();
          });
          menu.parent().find('a.selectLink').click(function (event) {
            event.preventDefault();
            event.stopPropagation();
            //console.log(menu);
            //console.log(menu.parent().parent().attr('id'));
            var ddname = menu.parent().parent().attr('id');
            var ddparts = ddname.split(".");
            ddparts.pop();
            var parentDiv = ddparts.join(".") + "_Content";
            $('div[id="' + ddname + '"]').trigger("click");
            $('div[id="' + parentDiv + '"]').trigger("click");
          });
          
        }
        //$(parentId[0]).css('border-color','#0C2ECF').css('background','#1E90FF');
        if (!forceRedo)
          selectDiv(parentId[0] + '_Content');
      }
    }
  }
}

function enableEditor($ckLink, editLink, parentId){
  if ($ckLink.length) {
    $ckLink.show();
    //$ckLink.html('<div class="edit-icon">&nbsp;</div>');
    $ckLink.css('cursor', 'pointer');
    $ckLink.off(); //clear listeners
    $ckLink.click(function (e) {
      e.preventDefault();
      //permissions could be on the second element (which was produced by assetManager)
      var permissions;
      var edit_inline;

      if (editLink.eq(1).data('permissions')) {
        permissions = editLink.eq(1).data('permissions');
        edit_inline = editLink.eq(1).data('editInline');
      } else {
        permissions = editLink.data('permissions');
        edit_inline = editLink.data('editInline');
      }

      editDiv(parentId[0] + '_Content', editLink.data('moduleObjectId'), editLink.data('objectId'), permissions, edit_inline);
      document.getElementById(parentId[0] + '_Content').style.backgroundColor = 'transparent';
    });
  }
}

function disableClickedDivs() {
  if (previousClicked) {

    //$(this).addClass('border-div').css("border-color","#CF1919").css("background-url",icon_path+"ddBG.png");
    var parentName = previousClicked;
    var parentId = parentName.split('_');

    if (parentName != null) {
      var parentId = parentName.split('_');
      var menu = $('div[id="menu_' + parentId[0] + '"]');
      //alert("div[id='menu_"+parentId[0]+"']");
      var editLink = menu.parent().find('a[class="menuJK' + parentId[0] + ' hideEdit"]');

      var contentDiv = $('div[id="' + parentId[0] + '_Content_Div_Edit"]');

      $('.infoDivs').each(function () {
        if ($(this).attr('id') != menu.attr('id')) {
          $(this).removeClass('menuOn');
          $(this).fadeOut('fast');
        }
      });

      menu.parent().find('.addLink').hide();
      menu.parent().find('.selectLink').hide();
      menu.parent().find('.pathLink').hide();
      menu.parent().find('.rmContents').hide();
      menu.parent().find('.ckLink').hide();

      if (editLink.html() != null) {
        var editHref = editLink.attr('href');
        var editTitle = editLink.html();
        menu.find('a.editLink').attr('href', editHref).html(editTitle);

        if (editLink.attr('target')) {
          menu.find('a.editLink').attr('target', editLink.attr('target'));
        } else if (editLink.attr('onclick')) {
          menu.find('a.editLink').click(editLink.attr('onclick'));
        }
      }

      if (contentDiv.html() != '') {
        menu.fadeIn('fast');
        menu.addClass('menuOn');
        menu.css('display', 'block');
        if (menu.find('a.editLink').html() == '&nbsp;') {
          //alert(menu.find('a.editLink').parent().attr('id'));
          menu.find('a.editLink').parent().attr('style', 'width:auto; left:auto; right:3px; display:block;');
        }
      }
      //$(parentId[0]).css('border-color','#0C2ECF').css('background','#1E90FF');
      if (lastDiv) {
        selectDiv(parentId[0] + '_Content');
      }
    }
  }
  previousClicked = null;
}

function deactivateLinks() {
  linkIDNum = 0;
  
  $("div[id='dd.0']").on('click',"a",function(e){  //any a within the editing area prevent click ability.
    if (!$(this).hasClass('hideEdit') && !$(this).hasClass('editLink') && $(this).attr('title') != 'Remove Contents'){
        e.preventDefault();
      }
  });
  
  $("div[id='dd.0']").find('a').each(function () {
    if (!$(this).hasClass('hideEdit') && !$(this).hasClass('editLink') && $(this).attr('title') != 'Remove Contents') {
    }
  });
  
  $("div[id='dd.0']").find("*").removeAttr('onclick'); //no onclick events when editing stml
}

function reactivateLinks() {
  /*
   $.each(linkURLs,function(i,val){
   $('a#'+i).attr('href',val);
   });
   */
}

function showDD() {
  var stmlFrame;

  if (document.getElementById('STML_frame')) {
    stmlFrame = document.getElementById('STML_frame').contentWindow.document;
  } else {
    stmlFrame = document;
  }

  var DDs = $('.dynamicDiv', stmlFrame);
  DDs.each(function () {
    $(this).toggleClass('active');
  });
}

utils.addToGlobal({
  activateDD,
  removeContent,
  showDD,
  beforeEditorSubmit,
  selectDiv,
});