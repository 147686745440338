var frameName = window.name;
var liveHTML = '';
var currentDiv;
var closeActionOnUpload;

function URLencode(sStr) {
  return escape(sStr).replace(/\+/g, '%2C').replace(/\"/g, '%22').replace(/\'/g, '%27');
}

function iFrameSrc(frameSrc, targetFrame) {
  var theIframe = parent.document.getElementById(targetFrame);
  theIframe.src = frameSrc;
}

var mmToMonth = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');

function getClockTime(val) {
  var now = new Date(val * 1000);
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  var ap = 'AM';

  if (hour > 11) {
    ap = 'PM';
  }

  if (hour > 12) {
    hour = hour - 12;
  }

  if (hour == 0) {
    hour = 12;
  }

  if (hour < 10) {
    hour = '0' + hour;
  }

  if (minute < 10) {
    minute = '0' + minute;
  }

  if (second < 10) {
    second = '0' + second;
  }

  var timeString = hour + ':' + minute + ' ' + ap;
  return timeString;
}

function formatDate(val, row) {
  if (val == 0) {
    return 'None';
  } else {
    var dt = new Date(val * 1000);
    var mm = mmToMonth[dt.getMonth()];
    var clocktime = getClockTime(val);
    var fullYear = dt.getFullYear();
    if(fullYear == "1969") return 'None';
    return mm + ' ' + dt.getDate() + ' ' + fullYear + ', ' + clocktime;
  }
}

function postProcess() {
}

function processObject() {
  var folderArray = node.id.split('_');

  if (folderArray[0] == 2 || folderArray[0] == 28 || folderArray[0] == 18) { //only accept asset files to be processed "2=module_object_id" -- and now datatables or calendars 1/25/15 -Ross
    document.getElementById('my_module_object_id').value = "" + folderArray[0];
    document.getElementById('object_id').value = "" + folderArray[1];
  }

  document.contentForm.elements['my_module_object_id'].value = folderArray[0];
  document.contentForm.elements['object_id'].value = folderArray[1];
  document.processObject.elements['my_module_object_id'].value = folderArray[0];
  document.processObject.elements['object_id'].value = folderArray[1];

  var success = AIM.submit(document.processObject, {'onStart': startCallback, 'onComplete': completeCallback});

  if (success) {
    document.processObject.submit();
  }
}

function RPC(jSrc) {
  var script = document.createElement('script');
  script.setAttribute('src', jSrc);
  script.setAttribute('language', 'Javascript');
  script.setAttribute('type', 'text/javascript');
  document.getElementsByTagName('head').item(0).appendChild(script);
}

function getCurrentDiv() {
  if (currentDiv) {
    return currentDiv;
  }
}

var formTables = new Array();

function registerTable(id, tableGroup) {
}

function reload() {
  location.reload(true);
}

function processURL(URL, Frame, GRID) {
  if (GRID) {
    if(top.$('#solodev-tabs').length){
      var currentContentWindow = top.$('#solodev-tabs').find('iframe.active')[0].contentWindow;
      if(currentContentWindow.$('#solodev-frame').length){
        var currentTab = currentContentWindow.$('#solodev-frame')[0];
      }else if(top.$('#solodev-tabs').find('iframe.active').length){
        var currentTab = top.$('#solodev-tabs').find('iframe.active')[0];
      }
    } else if(top.$('#solodev-frame').length){
      var currentTab = top.$('#solodev-frame')[0];
    }

    if (currentTab) {
      var rows = currentTab.contentWindow.$('#' + GRID).datagrid('getSelections');
      var selected = '';
      var glue = '';

      for (var object_id in rows[0]) {
        if (object_id.indexOf('.') != -1) {
          var object_key = object_id.split('.');
          object_id = object_key[1]
        }
        break;
      }

      if(URL.indexOf("?") == -1) var connector = "?";
      else  var connector = "&";

      if(rows.length == 0){
        if(confirm("Do you want to export the entire table?")){
          selected = 'all';
          URL = URL + connector + 'selected=' + selected + "&grid=" + GRID;
        }
      }else{
        var glue= '';
        for (var i = 0; i < rows.length; i++) {
          selected += glue+rows[i][object_id];  
          glue = ',';
        }
        URL = URL + connector + 'selected=' + selected;
      }
    }
  }
  goToFramedURL(URL);
}

function goToFramedURL(URL){
  var el = document.createElement('iframe');
  el.setAttribute('id', 'ifrm');
  el.setAttribute('width', '0');
  el.setAttribute('height', '0');
  el.setAttribute('border', '0');
  document.body.appendChild(el);
  el.setAttribute('src', unescape(URL));
}

function swap_div(div_name, div_title) {
  var openHeader = '<img src="/assets/portal/images/solodev/bullet_open.gif" style="padding-bottom:1px;"> ' + div_title;
  var closedHeader = '<img src="/assets/portal/images/solodev/bullet_closed.gif" style="padding-bottom:1px;"> ' + div_title;

  var div = document.getElementById(div_name);
  var to_height = (div.offsetHeight == 0) ? div.scrollHeight : 0;
  var from_height = (div.offsetHeight == 0) ? 0 : div.scrollHeight;
  var new_status = (from_height == 0) ? openHeader : closedHeader;

  $(div).animate({
    height: to_height
  }, 500, function () {
    // Animation complete.
  });

  document.getElementById(div_name + '_status').innerHTML = new_status;
}

var autosaveForms = 0;

function editorSubmit() {
  autosaveForms = 0;

  //needed preprocessing for saving stmls or experiments
  if (typeof window.solodevUtils.beforeEditorSubmit == 'function') {
    window.solodevUtils.beforeEditorSubmit();
  }
  
  var ckInstances = window.solodevUtils.ckInstances;
  if (ckInstances) {
    for (instance in ckInstances)
      ckInstances[instance].updateElement();
  }

  //alert(document.getElementById('pageContent').value);
  if (document.getElementById('STML_frame') && document.getElementById('STML_frame').contentWindow.document.getElementById('pageContent')) {
    document.getElementById('pageContent').value = document.getElementById('STML_frame').contentWindow.document.getElementById('pageContent').value;
  }

  $('form.autosave').each(function () {
    ++autosaveForms;
  });

  //prepare promise chain so the requests for saving the entry dont interfere with each other
  var chain = $.when();
  $('form.autosave').each(function (i, e) {
    var iframeOption = true; //for some reason the new chrome wont give a callback on the mapUserGroups form in iframe mode

    if ($(this).hasClass('ajax')) {
      iframeOption = false;
    }

    var self = this;
    chain = chain.then(function () {
      var promise = $.Deferred();
      $(self).ajaxSubmit({
        iframe: iframeOption, 
        success: function(){
          --autosaveForms;
          promise.resolve();
        }
      });
      return promise;
    });
  });

  if (autosaveForms == 0) {
    submitForm();
  }else{
    chain.done(function(){
      submitForm();
    });
  }
}

function submitForm() {
  if (autosaveForms <= 0) {
    if ($('#contentForm').valid()) {
      document.getElementById('contentForm').submit();
    }
  }
}

var id_caller;
var objects;
var objectID;

function showChooser(id_name, objects, module_object_id) {
  id_caller = id_name;
  objects = objects;
  document.getElementById('object_id').value = document.getElementById(id_name).value;
  document.getElementById('object_name').value = document.getElementById(id_name).value;
  //console.log(document.getElementById('mainArea'));
  document.getElementById('mainArea').style.display = 'none';
  //$('#objectChooser').css('cssText','display:none !important;');  //need to let the object chooser have a set height so it can scroll.  Greg.
  $('#objectChooser').show();

  $('#object-tree').tree({url: '/tree?objects=' + objects});

  if (document.getElementById(id_name).value) {
    objectID = module_object_id + '_' + document.getElementById(id_name).value + '_t';
  }
}

function updateChooser() {
  //alert(document.chooser.elements['object_id'].value);
  //if (document.getElementById('object_id').value) //need to be able to clear out the set value
  if(document.getElementById('object_name').value == "") var fieldValue = "";
  else var fieldValue = document.getElementById('object_id').value;
  
  document.getElementById(id_caller).value = fieldValue;
  if (typeof postProcess == 'function') {
    processUpdates(id_caller);
  }
  //$('#objectChooser').css('cssText','display:none !important;');
  $('#objectChooser').hide();
  document.getElementById('mainArea').style.display = 'block';
}

function cancelChooser() {
  document.getElementById('mainArea').style.display = 'block';
  $('#objectChooser').hide();
  //$('#objectChooser').css('cssText','display:none !important;');
}

var button_caller;

function showForm(id_name, button_id, asset_category_id, asset_file_id) {
  top.$('#ok').linkbutton('disable');
  id_caller = id_name;
  button_caller = button_id;

  document.getElementById('mainArea').style.display = 'none';
  $('#form_editor').css('cssText', 'display:block !important;');

  if (asset_file_id) {
    var portalProcess_1 = 'showAssetFileTemplate&asset_file_id=' + asset_file_id;
  } else {
    var portalProcess_1 = 'showAddFormTemplate';
  }

  var form_URL = '/portal?module_dm_id=1&portalProcess_1=' + portalProcess_1 + '&asset_category_id=' + asset_category_id + '&RPC=1&chooser=1';
  //alert(form_URL);
  $('#form_editor_area').load(form_URL, function () {
  });
}

function updateForm() {
  var success = AIM.submit(document.form_editor_form, {
    'onStart': startFormSaveCallback,
    'onComplete': completeFormSaveCallback});

  if (success) {
    document.form_editor_form.submit();
  }

  document.getElementById('mainArea').style.display = 'block';
  $('#form_editor').css('cssText', 'display:none !important;');
}

function cancelForm() {
  document.getElementById('mainArea').style.display = 'block';
  $('#form_editor').css('cssText', 'display:none !important;');

  if (typeof postProcess == 'function') {
    processCancel(id_caller);
  }
}

function startFormSaveCallback() {
  return true;
}

function completeFormSaveCallback(response) {
  var asset_file_id = response.split('ID:');

  if (asset_file_id[1]) {
    //var form_id = asset_file_id[1].split(' ');
    var form_id = asset_file_id[1].trim().split(' ');
    document.getElementById(id_caller).value = form_id[0];
  }

  if (typeof postProcess == 'function') {
    processUpdates(id_caller);
  }
  return false;
}

function showDatatableCategoryChooser(id_name, objects, module_object_id) {
  id_caller = id_name;
  objects = objects;

  document.getElementById('object_id').value = document.getElementById(id_name).value;
  document.getElementById('object_name').value = document.getElementById(id_name).value;
  document.getElementById('mainArea').style.display = 'none';
  document.getElementById('datatableCategoryChooser').style.display = 'block';

  $('#object-tree').tree({url: '/tree?objects=' + objects});

  if (document.getElementById(id_name).value) {
    objectID = module_object_id + '_' + document.getElementById(id_name).value + '_t';
  }
}

function updateDatatableCategoryChooser() {
  if (document.getElementById('object_id').value) {
    var fieldValue = document.getElementById('object_id').value;
    document.getElementById(id_caller).value = fieldValue;

    if (typeof postProcess == 'function') {
      processUpdates(id_caller);
    }
  }

  document.getElementById('datatableCategoryChooser').style.display = 'none';
  document.getElementById('mainArea').style.display = 'block';
}

function cancelDatatableCategoryChooser() {
  document.getElementById('mainArea').style.display = 'block';
  document.getElementById('datatableCategoryChooser').style.display = 'none';
}

function var_dump(obj) {
  var out = '';
  for (var i in obj) {
    out += i + ": " + obj[i] + "\n";
  }
  alert(out);
}

function styleInput(jqElement, message) { // if any field in validateFormData comes back with a custom message this will put an error label on the field
  var elementID = jqElement.attr('id')
  jqElement.after('<label class="error" for="' + elementID + '">' + message + '</label>');
  parent.solodevUtils.enableSubmit(); //action_window.js
}

$(document).on('click', ".solodev_clear_button", function(){
	var theInput = $(this).data("idName");
	var theInputDisplay = theInput + "_display";
	$('#'+theInput).val("");
	$('#'+theInputDisplay).val("");
});

$(document).on('click', "[id^=action_bar_] a", function(){  //remove onclick to prevent double submissions - put onclick back after 1 second.
	
	if($('#contentForm').length){ //check for existing to prevent js error below.
		if ($('#contentForm').valid()) {
			var onclickEvent = $(this).attr("onclick");
			var theButton = $(this);
			theButton.attr("onclick","");
			setTimeout(function(){
				theButton.attr("onclick",onclickEvent);
			}, 1000);
		}
	}
});

//code below was attempt at making the object chooser pop up go over the entire action window
/*
$(document).ready(function(){
	$(".chooserModal").on("click",function(event){
		
		var button = $(this); // Button that triggered the modal
	  var objects = button.data('objects');
	  var module_object_id = button.data('moduleObjectId');
	  var id_name = button.data('idName');
		id_caller = id_name;
		
		window.parent.showObjectChooser(id_name, objects, module_object_id);
		
	});
});
*/

// BOOTSTRAP 4 Basic validation, to form tag add:  class="needs-validation" novalidate
(function() {
  'use strict';

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });

})();

$(document).ready(function() {
  //$('.portal #solodev-frame').css('margin-top', '45px');
  /*
  $(".childrenSearcher").click(function(){
    var subfolderVal = $('#mySearchChildren').val();
    if(subfolderVal == 1){
     $('#mySearchChildren').val(0);
     $(this).removeClass("selected");
    }else{
     $('#mySearchChildren').val(1);
     $(this).addClass("selected");
    }
  });*/

});

$(document).on('click', "a[href='#'].panel-action, a[href='#'].btn", function(e) {
e.preventDefault();
});


utils.addToGlobal({
  formatDate,
  editorSubmit,
  processURL,
  RPC,
  styleInput
});