(function($) {

  $.fn.activateUploader = function(formData, uploadSuccess, autoUpload, singleFileUpload) {

    var uploader = $('#' + $(this).attr('id'));
    var ul = $('#' + $(this).attr('id') + '-queue');

    $('a').click(function() {
      // Simulate a click on the file input button
      // to show the file browser dialog
      $(this).parent().find('input').click();
    });
    if ($(this).data('blueimp-fileupload') || $(this).data('fileupload')) {
      $(this).fileupload('destroy');
    }

    // Initialize the jQuery File Upload plugin
    $('#' + $(this).attr('id')).fileupload({
      // This element will accept file drag/drop uploading
      dropZone: $('#drop'),
      formData: formData,
      autoUpload: autoUpload,
      maxNumberOfFiles: 1,
      // url: '/assets/vendor/fileupload/upload.php',
      // url: '/core/fileupload/upload.php',
      url: '/upload',
      // This function is called when a file is added to the queue;
      // either via the browse button, or via drag/drop:
      add: function(e, data) {
        if (singleFileUpload) {
          ul.empty();
        }

        var parent_category_id = $(this).fileupload('option','formData').asset_category_id;
        //var file_checker_url = '/api.php/search/asset_category/'+parent_category_id+'?types=["Asset_File"]&qry={}&orderStr=name&orderDir=asc&table_filter='+data.files[0].name;
        // var file_checker_url = '/api/api.php/search/asset_file?qry={"$and":[{"name":"'+data.files[0].name+'"},{"parent_category_id":'+parent_category_id+'}]}&timestamp='+timestamp+'&nonce='+nonce;
        var file_checker_url = '/api/search/asset_file?qry={"$and":[{"name":"'+data.files[0].name+'"},{"parent_category_id":'+parent_category_id+'}]}&timestamp='+timestamp+'&nonce='+nonce;
        
        $.getJSON(file_checker_url, function (result) {
          var warningMarkup='';
          var warningTitle='';
          var alertClass='';
          //console.log(result);
          if(result.asset_file.length > 0){  //file already exists
            warningTitle = 'title="Existing file will be overwritten."';
            warningMarkup = '<i aria-hidden="true" class="fas fa-exclamation-triangle font-weight-bold d-inline ml-2" '+warningTitle+'></i>';
            alertClass=' alert-danger';
          }

        var tpl = $('<li class="working d-flex align-items-center justify-content-between'+alertClass+'" '+warningTitle+'><input type="text" value="0" data-width="48" data-height="48"' + ' data-fgColor="#0088cc" data-readOnly="1" data-bgColor="#E5E5E5" /><p class="text-truncate w-xs-300p"></p><span class="mr-2"></span></li>');
        // Append the file name and file size
        tpl.find('p').text(data.files[0].name).append(warningMarkup+'<i>' + formatFileSize(data.files[0].size) + '</i>');

        // Add the HTML to the UL element
        data.context = tpl.appendTo(ul);

        // Initialize the knob plugin
        tpl.find('input').knob();

        var jqXHR = null;

        // Listen for clicks on the cancel icon
        tpl.find('span').click(function() {

          tpl.fadeOut(function() {
            tpl.remove();
          });

        });



      });  ///end file exists check api call

      if (autoUpload) data.submit();

      $('#' + $(this).attr('id')).on("uploadfiles", function() {
        jqXHR = data.submit();
      });


      },
      progress: function(e, data) {

        // Calculate the completion percentage of the upload
        var progress = parseInt(data.loaded / data.total * 100, 10);

        // Update the hidden input field and trigger a change
        // so that the jQuery knob plugin knows to update the dial
        data.context.find('input').val(progress).change();

        if (progress == 100) {
          data.context.removeClass('working');
        }
      },
      fail: function(e, data) {
        // Something has gone wrong!
        data.context.addClass('error');
      },
      destroy: function(e, data) {
        if (e.isDefaultPrevented()) {
          return false;
        }
        var that = $(this).data('blueimp-fileupload') ||
          $(this).data('fileupload'),
          removeNode = function() {
            that._transition(data.context).done(
              function() {
                $(this).remove();
                that._trigger('destroyed', e, data);
              }
            );
          };
        if (data.url) {
          data.dataType = data.dataType || that.options.dataType;
          $.ajax(data).done(removeNode).fail(function() {
            that._trigger('destroyfailed', e, data);
          });
        } else {
          removeNode();
        }
        console.log("data", data);
        //alert('done');
      },
      getNumberOfFiles: function() {
        alert("getting number for files");
        return ul.children()
                .not('.processing').length;
      },
    });

    var fileUploadCount=0;

    $('#' + $(this).attr('id')).bind("fileuploaddone", function(e, data) {
      fileUploadCount++;
      if(data.originalFiles.length == fileUploadCount) uploadSuccess(data);  //only call uploadSuccess after all the files have been uploaded.
    });

    // Prevent the default action when a file is dropped on the window
    $(document).on('drop dragover', function(e) {
      e.preventDefault();
    });

    // Helper function that formats the file sizes
    function formatFileSize(bytes) {
      if (typeof bytes !== 'number') {
        return '';
      }

      if (bytes >= 1000000000) {
        return (bytes / 1000000000).toFixed(2) + ' GB';
      }

      if (bytes >= 1000000) {
        return (bytes / 1000000).toFixed(2) + ' MB';
      }

      return (bytes / 1000).toFixed(2) + ' KB';
    }

  }
})(window.jQuery);