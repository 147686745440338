export default class ObjectChooser {
  
  constructor() {
    const objChooserModal = document.getElementsByTagName('data-target="#objChooserModal"');
    if (objChooserModal) {
      this.initObjChooserModal();
    }
  }

  initObjChooserModal() {
    const self = this;

    $(document).on('click', 'button[data-target="#objChooserModal"], input[data-target="#objChooserModal"]', function () {
      self.objChooserModal($(this));
    });

    $(document).on('keyup', 'button[data-target="#objChooserModal"], input[data-target="#objChooserModal"]', function (event) {
      if (event.key === 13 || event.key === 'Enter') {
        self.objChooserModal($(this));
      };
      if (event.key === 'Escape' || event.key === 'Esc' || event.key === 27) {
        top.solodevUtils.closeAction();
      }
    });
    
    $('#object-tree').tree({
      onClick:function(node) { 
        self.processChooserObject(node);
      },
      onCheck:function(node) {
        self.processChooserObject(node);
      },
      onExpand:function(node) {
        $('#iLogic').attr('src', '/tree?expand_node=1&id='+node.id);
      },
      onCollapse:function(node) {
        $('#iLogic').attr('src', '/tree?close_node=1&id='+node.id);
      },
      onLoadError:function() {
        alert('load error');
      },
      onLoadSuccess:function(node) {
        if (typeof postProcess == 'function') {
          postProcess();
        }
      }
    });
    
    this.ModalContentHeight();
    window.addEventListener('resize', this.ModalContentHeight());
    
  }
  objChooserModal(button) {
    const chooserModal = top.$('#objChooserModal'),
    objects = button.data('objects'),
    id_name = button.data('idName'),
    displayCaller = id_name + '_display',
    experimentId = button.data('experiment');
  
    if (experimentId) chooserModal.find('.btn-choose').data('openerparent', experimentId);
  
    chooserModal.find('.btn-choose').data('opener', id_name);
    chooserModal.find('#object_id').val(document.getElementById(id_name).value);
    chooserModal.find('#object_name').val(document.getElementById(displayCaller).value);
    chooserModal.find('#object-tree').tree({url: '/tree?objects=' + objects});
    chooserModal.modal('show');
  }
  processChooserObject(node){
    const chooserModal = top.$('#objChooserModal'),
    name = node.text,
    selectedObject = node.id.split('_');
    chooserModal.find('#my_module_object_id').val(selectedObject[0]);
    chooserModal.find('#object_id').val(selectedObject[1]);
    chooserModal.find('#object_name').val(name);
  }
  
  ModalContentHeight() {
    const containers = document.getElementsByClassName('modal-content'),
    height = window.innerHeight + 'px';

    if (containers) {
      for (let i = 0; i < containers.length; i++) {
        containers[i].style.height = height;
      }
    }
  }
}