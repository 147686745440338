export function RelationshipParsing() { 

  var relationshipTable = document.getElementById("relationship-table");
  var input_str = document.getElementById("table_relationships").value;
  var chooserCount = 0;
  
  var relationshipObjs = new Array();
  if(input_str){
    relationshipObjs = JSON.parse(input_str);
  }
  var dataTypes = [{name:'One to One',value:1},{name:'One to Many',value:2},{name:'Many to Many',value:3}];
  var chooserCounter = 1;
  $.each(relationshipObjs, function(index, oneObject) {
    
    var row = document.createElement('tr');
    var col_index = 0;
    
    var cell = row.insertCell(col_index);
    getRelationshipCell(col_index, oneObject.relationship_name ,cell);
    col_index++;
    
    var cell = row.insertCell(col_index);
    getRelationshipCell(col_index, oneObject.type ,cell);
    col_index++;
    
    var cell = row.insertCell(col_index);
    getRelationshipCell(col_index, oneObject.module ,cell);
    col_index++;
    
    var fieldcell = row.insertCell(col_index);
    getRelationshipCell(col_index, oneObject.field ,fieldcell);
    col_index++;
    
    $(row).append(('<td class="edit-btn"><button type="button" class="delete-row btn"><i class="fa fas fa-minus"></i></button></td>'));
    $(relationshipTable).find('tbody').append(row);
    
    processUpdates("related_module"+chooserCounter);  //related_module
    
    console.log($('option[value = '+oneObject.field+']', fieldcell));
    
    $('option[value = '+oneObject.field+']', fieldcell).prop('selected', true);
    chooserCounter++;
  
  });

  function getRelationshipCell(col_index, colValue,cell) {
    var columnType = $(relationshipTable).find('thead').find('th:eq('+ col_index + ')').attr('column-type');               
    var dropDown = $('<select></select>');
      if(columnType == 'type') {           

          $.each(dataTypes, function (index,obj) {   
              dropDown.append('<option value = '+obj.value+'>'+obj.name+'</option>');
          });
          dropDown.addClass('form-control');
          $(cell).append(dropDown);
          colValue = "'"+colValue+"'";
          $('option[value = '+colValue+']', cell).prop('selected', true);
                            
    
      } else if(columnType == 'text') {
        if(colValue!==null)
          var input = $('<input type="text" class="name form-control" value='+colValue+'></input>');
        else
            var input = $('<input type="text" class="name form-control"></input>');
        $(cell).append(input);

      } else if(columnType == 'emptySelect'){
      
          var dropDown = $("<select data-choose='"+colValue+"' class='select-field form-control'></select>");
          dropDown.append('<option value="" selected disabled="disabled">Choose Field</option>');
          $(cell).append(dropDown); 
      
      } else if(columnType == 'chooser'){
          chooserCount++;
          var related_mod_id = "";
          var related_mod_name = "";
          if(colValue){
            related_mod_id = colValue.object_id;
            related_mod_name =  colValue.object_name;
          }
          
          var chooserMarkup = '<div class="input-group">'+
                              '<input class="related_module" name="related_module" type="hidden" value="'+related_mod_id+'" id="related_module'+chooserCount+'">'+
                              '<input class="form-control" value="'+related_mod_name+'" name="related_module_display" id="related_module'+chooserCount+'_display" type="text" data-id-name="related_module'+chooserCount+'" data-objects="Datatable" data-module-object-id="28" data-toggle="modal" data-target="#objChooserModal" autocomplete="off">'+
                              '<span class="input-group-btn"><button class="btn btn-white border border-gainsboro solodev_clear_button" type="button" data-id-name="related_module'+chooserCount+'">Clear</button>'+
                              '<button class="btn btn-info" type="button" data-id-name="related_module'+chooserCount+'" data-objects="Datatable" data-module-object-id="28" data-toggle="modal" data-target="#objChooserModal">Browse</button>'+
                              '</span></div>';
          $(cell).append(chooserMarkup);
      }
      else if(columnType == 'length') {
          var type = $(cell).closest('tr').find('.form-control').val();
          var dropDown = $("<select class='select-length'></select>");           
          var lengthOpts = lengthOptions[type];          
          if(lengthOpts!== undefined) {
              $.each(lengthOpts, function (index,obj) {   
                  dropDown.append('<option value = '+obj.value+'>'+obj.name+'</option>');
              });
              
          } else {              
              dropDown.css("display","none");
          }          
          dropDown.addClass('select-length');         
          $(cell).append(dropDown); 
          $(cell).addClass('length');
          if(lengthOpts!== undefined) {
          colValue = "'"+colValue+"'";          
          $('option[value = '+colValue+']', cell).prop('selected', true);  
          }      
          
      } else if(columnType == 'custom') {
          $(cell).append(('<button type="button" class="delete-row btn btn-xs btn-scarlet"><i class="fa fas fa-minus"></i></button></button>'));
    
      } else if(columnType == 'nullCheck') {
          colValue = (colValue!==null && colValue!=='')?colValue: 'NOTNULL';
          $(cell).append('<input class="null-check" type="checkbox" value='+colValue+'>');           
          if(colValue === 'NULL')
              $(cell).find('input[type="checkbox"]').prop('checked',true);               
      } else if(columnType == 'default') {
          $(cell).append('<input class="defaultVal form-control" type="text"></input>'); 

      }else if(columnType == 'defaultVal') {            
          if(colValue!==null && colValue!=='') {
              colValue = colValue.replace(/'/g, "");
              $(cell).closest('tr').find('.defaultVal').val(colValue); 
          }
          $(cell).css("display","none");
      }
  }
    
  $('#relationship-table .add-row').on('click', function (){     
    var row = document.createElement('tr');
    $('#relationship-table thead').find('th').each(function(index){
      var cell = row.insertCell(index);
      var columnType = $(this).attr('column-type');
      if(columnType)
        getRelationshipCell(index,null,cell);
    });      
    $('#relationship-table').find('tbody').append(row);
  });

  $('#relationship-table').delegate('.delete-row', 'click', function (){
    var r = confirm("Are you sure you want to delete this relationship?")
    if(r==true) $(this).closest('tr').remove();
  }); 
  
  $(document).on('keyup', '.name', function(e){
    var start = this.selectionStart,
    end = this.selectionEnd;  //save cursor position to restore after value update.
    
    var keyCode = e.keyCode;
    if (keyCode == 39 || keyCode == 37) {  //Let navigation with left and right arrows
        return;
    }
    //field restrictions: lowercase, spaces replaced by _, only numbers and letters, cannot start with a number.
    var nameValue = $(this).val().toLowerCase().replace(/\ /g, "_").replace(/[^a-zA-Z0-9 _]/g,'').replace(/^\d/g, "");
    $(this).val(nameValue);
    
    this.setSelectionRange(start, end); //restore cursor position after value update
  });
}
    
export function saveRelationships() {
//Add Primary Field
var relationshipArr = [];
var myRelationshipName;
var myRelationshipType;
var myModuleField;

  $('#relationship-table tbody').find('tr').each(function(){
  var myModule = {};
  var myRelationshipName = $(this).find('.name').val();
  if(myRelationshipName != '' && myRelationshipName!=null && myRelationshipName.search("entry_id")===-1 ) {
        $(this).find('td:not(:last-child)').each(function() {                                           
          var val = $(this).children().val();
          if(typeof val != "undefined" && val != '' && val!=null ){
          
            if($(this).children().hasClass('form-control')) {
            myRelationshipType = val;
            }
            if($(this).children().hasClass('select-field')) {
            myModuleField = val;
            }
        }else if($(this).children().hasClass('input-group')) {
            myModule.object_id = $(this).children().find('.related_module').val();
            myModule.object_name = $(this).children().find('input[name=related_module_display]').val();
            myModule.module_object_id = 28;
        }
        
      });
      
      relationshipArr.push({
        relationship_name: myRelationshipName,
        type: myRelationshipType,
        field: myModuleField,
        module: myModule
      });
  }
  });
  
  var jsonstring = JSON.stringify(relationshipArr);
  document.getElementById('table_relationships').value = jsonstring;
}