export default class Search {
  
  constructor() {
    const search = document.getElementById('search_search');

    if (search) {
      this.search = search;
      this.searchTransitions();
    }
  }
  searchTransitions() {
    const searchTable = document.getElementById('searchTable'),
    searchHelpText = document.getElementById('searchHelpText'),
    dashboard = document.getElementById('dashboard'),
    close = document.querySelector('.close-search');

    this.search.addEventListener('focus', () => {
      console.log(searchTable);
      searchTable.classList.remove('d-none');
      searchTable.classList.add('d-block');

      searchHelpText.classList.remove('d-none');
      dashboard.classList.add('d-none');
      
      close.classList.add('d-flex');
      close.classList.remove('d-none');
    });
    this.search.addEventListener('blur', () => {
      searchHelpText.classList.add('d-none');

      close.classList.add('d-none');
      close.classList.remove('d-flex');
    });
    close.addEventListener('focus', () => {
      searchTable.classList.remove('d-block');
      searchTable.classList.add('d-none');
      
      dashboard.classList.remove('d-none');
      close.classList.remove('d-flex');
    });
  }
}