export function SchemaParsing() { 

  var schemaTable = document.getElementById("schema-table");
  //var input_str ="small_icon I(11) NULL DEFAULT '0',\nlarge_icon I(11) NULL DEFAULT,\ndate_added I(11) NOTNULL DEFAULT '0',\ndate_modified I(11) NOTNULL DEFAULT '0' ";
  var input_str = document.getElementById("table_schema").value;
  //alert(input_str);
  
  var input_arr = splitString(input_str);
  function splitString(input_str) {
    var arr = [];
    var tempToken = '';
    var i = 0;
    var parenOpen = false;
    while(input_str[i]) {
      if(input_str[i] === '(') {
        parenOpen = true;
      }
      else if(input_str[i] === ')') {
        //console.log('hello world');
        parenOpen = false;
      }
      else if(input_str[i] === ',' && !parenOpen) {
        arr.push(tempToken);
        tempToken = '';
      }
      if(input_str[i] !== ',' || parenOpen) {
        tempToken += input_str[i];
      }
      i++;
    }
    if(tempToken.length) {
      arr.push(tempToken);
    }
    return arr;
  }

  var lengthOptions = {"C": [{ "name":"64","value":"64"}, {"name":"255","value":"255"},{"name":"1","value":"1"}], "X":[{"name":"Large","value":"Large"}], "I":[{"name":"1","value":"1"}, {"name":"11","value":"11"}], "F":[{"name":"11","value":"11"}], "N":[{"name":"8,2","value":"8,2"}]};
  var dataTypes = [{name:'Character',value:'C'},{name:'Large Text',value:'X'},{name:'Blob',value:'B'},{name:'File',value:'F'},{name:'Date Field',value:'D'},{name:'TimeStamp',value:'T'},{name:'Integer',value:'I'},{name:'numeric',value:'N'},{name:'Serial Field', value:'R'}];
  var defaultTypes = [{name:'NULL',value:'NULL'},{name:'NOTNULL',value:'NOTNULL'},{name:'0',value:'0'},{name:'1',value:'1'}];

  $.each(input_arr, function(index, value) {      
    if(value.search("entry_id")===-1) {
      var row = document.createElement('tr');
      value = value.trim();       
      var col_arr = value.split(' ');
      var col_index = 0;
      $.each(col_arr, function(innerindex, innervalue) {
        var regExp = /\(([^)]*)\)/;
        var matches = regExp.exec(innervalue);
        if(matches && matches[1]!==null) {
          var cell = row.insertCell(col_index);
          var cellValue = innervalue.replace(matches[0],"");
          getCell(col_index,cellValue,cell);
          col_index = col_index +1; 
          var cell = row.insertCell(col_index);
          getCell(col_index,matches[1],cell);
          col_index = col_index+1;
        } else if((col_index == 1) && (["C","X","I","N","B","D","T","R","F"].indexOf(innervalue) > -1) ) {
          var cell = row.insertCell(col_index);
          var cellValue = innervalue;
          getCell(col_index,cellValue,cell);
          col_index = col_index +1; 
          var cell = row.insertCell(col_index);
          getCell(col_index,"",cell);
          col_index = col_index+1;
        } else {
          var cell = row.insertCell(col_index);
          getCell(col_index, innervalue ,cell);
          col_index = col_index +1;
        }
      });
      
    if($(row).find('td').length<5) {
      $(row).append('<td class="default"><input class="form-control defaultVal" type="text"></input> </td>');  
    }
    $(row).append(('<td class="edit-btn"><button type="button" class="delete-row btn btn-xs btn-scarlet"><i class="fa fa-minus"></i></button></td>'));        
    $(schemaTable).find('tbody').append(row);
  }
});

function getCell(col_index, colValue,cell) {             
  var columnType = $(schemaTable).find('thead').find('th:eq('+ col_index + ')').attr('column-type');
  var format = $(schemaTable).find('thead').find('th:eq('+ col_index + ')').attr('format');
  var inputName = $(schemaTable).find('thead').find('th:eq('+ col_index + ')').attr('input-name');
  var includeCounter = $(schemaTable).find('thead').find('th:eq('+ col_index + ')').attr('counter');
  var rowCounter = "";
  
  if (typeof includeCounter !== 'undefined') {
    rowCounter = $(schemaTable).find('tr').length;
  }

  var propertyInputName="";
  if (typeof inputName !== 'undefined') {
    propertyInputName = ' name="'+inputName+rowCounter+'"';
  }

  var dropDown = $("<select></select>");

  if(columnType == 'type') {
    $.each(dataTypes, function (index,obj) {
      dropDown.append('<option value = '+obj.value+'>'+obj.name+'</option>');
    });
    dropDown.addClass('type');
    dropDown.addClass('form-control');
    $(cell).append(dropDown);
    colValue = "'"+colValue+"'";
    $('option[value = '+colValue+']', cell).prop('selected', true);

  } else if(columnType == 'text') {
    var formatClass="";
    if (typeof format !== 'undefined' && format == 1) {
      formatClass = " format";
    }

    if(colValue !== null)
      var input = $('<input'+propertyInputName+' type="text" class="name form-control'+formatClass+'" value='+colValue+'></input>');
    else
      var input = $('<input'+propertyInputName+' type="text" class="name form-control'+formatClass+'"></input>');
    $(cell).append (input);

    } else if(columnType == 'length') {
        var type = $(cell).closest('tr').find('.type').val();
        var dropDown = $("<select class='select-length form-control'></select>");
        var lengthOpts = lengthOptions[type];          
        if(lengthOpts!== undefined) {
          $.each(lengthOpts, function (index,obj) {   
            dropDown.append('<option value = '+obj.value+'>'+obj.name+'</option>');
          });
        } else {
          dropDown.css("display","none");
        }
        dropDown.addClass('select-length');
        $(cell).append(dropDown); 
        $(cell).addClass('length');
        if(lengthOpts!== undefined) {
        colValue = "'"+colValue+"'";
        $('option[value = '+colValue+']', cell).prop('selected', true);
        }
    } else if(columnType == 'defaultDropdown'){
      var dropDown = $("<select"+propertyInputName+" class='form-control "+inputName+"'></select>");   
      dropDown.append('<option value="">Choose</option>');
      $(cell).append(dropDown); 
    } else if(columnType == 'custom') {
      $(cell).append(('<button type="button" class="delete-row btn btn-xs btn-scarlet"><i class="fas fa-minus"></i></button>'));
    } else if(columnType == 'nullCheck') {
      colValue = (colValue!==null && colValue!=='')?colValue: 'NOTNULL';
      $(cell).append('<input class="null-check" type="checkbox" value='+colValue+'>');
      if(colValue === 'NULL')
        $(cell).find('input[type="checkbox"]').prop('checked',true);
    } else if(columnType == 'check') {
      colValue = (colValue!==null && colValue!=='')?colValue: '0';
      $(cell).append('<input'+propertyInputName+' class="check" type="checkbox" value='+colValue+'>');           
      if(colValue === 'NULL')
        $(cell).find('input[type="checkbox"]').prop('checked',true);
    } else if(columnType == 'default') {
      $(cell).append('<input class="defaultVal form-control" type="text"></input>'); 
    } else if(columnType == 'defaultVal') {
      if(colValue!==null && colValue!=='') {
        colValue = colValue.replace(/'/g, "");
        $(cell).closest('tr').find('.defaultVal').val(colValue); 
      }
      $(cell).css("display","none");
    } else if(columnType == 'upload') {
      var input = $('<input'+propertyInputName+' type="file" class="input-name form-control"></input>');
      $(cell).append(input);
    }
  }

  $('#schema-table .add-row').on('click', function (){     
    var row = document.createElement('tr');
    $('#schema-table thead').find('th').each(function(index){
      var cell = row.insertCell(index);
      var columnType = $(this).attr('column-type');
      if(columnType)
        getCell(index,null,cell);
    });      
    $('#schema-table').find('tbody').append(row);
    if (typeof afterAddRow === "function") {
      afterAddRow(row);
    }
  });

  $('#schema-parse-div #schema-table').delegate('.delete-row', 'click', function (){
    var r = confirm("Deleting this field will remove any data stored here from existing entries.");
    if(r==true) $(this).closest('tr').remove();
  }); 

  $('#w4-websettings #schema-table').delegate('.delete-row', 'click', function (){
    $(this).closest('tr').remove();
  }); 

  $('#schema-table' ).delegate('.null-check','click',function(){
    if($(this).is(':checked')){
      $(this).val("NULL");
    } 
    else {
      $(this).val("NOTNULL");
    } 
  });

  $('#schema-table').delegate('.type', 'change', function () {         
    var type = $(this).val();       
    var cell = $(this).closest('tr').find('.length');
    $(cell).empty();
    var dropDown = $("<select class='select-length form-control'></select>");           
    var lengthOpts = lengthOptions[type];          
    if(lengthOpts!== undefined) {
      $.each(lengthOpts, function (index,obj) {   
        dropDown.append('<option value = '+obj.value+'>'+obj.name+'</option>');
      });
    } else {
      dropDown.css("display","none");
    }
    $(cell).append(dropDown);
  });
  
  $(document).on('keyup', '#schema-parse-div .name, .name.format', function(e){
    var start = this.selectionStart,
    end = this.selectionEnd;  //save cursor position to restore after value update.
    
    var keyCode = e.keyCode;
    if (keyCode == 39 || keyCode == 37) {  //Let navigation with left and right arrows
      return;
    }
    //field restrictions: lowercase, spaces replaced by _, only numbers and letters, cannot start with a number.
    var nameValue = $(this).val().toLowerCase().replace(/\ /g, "_").replace(/[^a-zA-Z0-9 _]/g,'').replace(/^\d/g, "");
    $(this).val(nameValue);
    
    this.setSelectionRange(start, end); //restore cursor position after value update
  });
}

export function saveSchema () {
//Add Primary Field
  var schemaStr = "primary_entry_id I(11) NOTNULL"+","+"\n";
  //alert(schemaStr);
  
  $('#schema-table tbody').find('tr').each(function(){
    var nameVal = $(this).find('.name').val();
    //alert(nameVal); 
    if(nameVal != '' && nameVal!=null && nameVal.search("entry_id")===-1 ) {
      $(this).find('td:not(:last-child)').each(function() {                                           
        var val = $(this).children().val();  
        
        if(typeof val != "undefined" && val != '' && val!=null ) {           
          if($(this).children().hasClass('select-length')) {
            val = "("+val+")";
            schemaStr = schemaStr.trim() 
          }
          if($(this).children().hasClass('defaultVal')) {
            if(val!=''){
              val = 'DEFAULT'+" "+"'"+val+"'";
            }
            else{
              val = "DEFAULT";
            }                
          }
          schemaStr = schemaStr+val+" ";
        }
      });
      schemaStr = schemaStr.trim()+","+"\n";
    }
  });
  
  schemaStr = schemaStr.substring(0,schemaStr.length-2);
  
  //alert(schemaStr);
    
  document.getElementById('table_schema').value = schemaStr;
    
}